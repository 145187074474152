import React, { Component } from "react";
import { propertyUtil } from "views/Pma/PropertyUtil";

class IndicatorSection extends Component {
  formatCategory = (category) => {
    const { useMedianSalePrice } = this.props;
    if (category === "Sale Prices") {
      return (
        <div className="indicator-category">
          <p>{useMedianSalePrice ? "Median Sale Price" : "Sale Price"}</p>
        </div>
      );
    } else if (category === "Days to Sell") {
      return (
        <div className="indicator-category">
          <p>Days on Market</p>
        </div>
      );
    } else if (category === "Square Ft") {
      return (
        <div className="indicator-category">
          <p>Price Per Sq Ft</p>
        </div>
      );
    } else if (category === "Homes for Sale") {
      return (
        <div className="indicator-category">
          <p>For Sale</p>
        </div>
      );
    } else if (category === "% Received of Asking") {
      return (
        <div className="indicator-category">
          <p>% Received of Asking</p>
        </div>
      );
    }
  };
  formatValue = (value) => {
    const { category } = this.props;
    const { roundSalePrice } = propertyUtil;
    if (category === "Sale Prices") {
      //console.log("sale prices", value);
      return `${roundSalePrice(value).toLowerCase()}`;
    } else if (category === "Days to Sell") {
      return `${Math.round(value)} Days`;
    } else if (category === "Square Ft") {
      return `$${Number(value).toLocaleString()}`;
    } else if (category === "Homes for Sale") {
      return `${value} ${value == 1 ? "Home" : "Homes"}`;
    } else if (category === "% Received of Asking") {
      return `${Math.round(value)}%`;
    }
  };

  formatArrow = (perc, category) => {
    perc = +perc;
    const { mlsAreaID } = this.props.currentArea;
    if (perc > 0) {
      return (
        <img
          src="https://pma-coverphotos.s3.us-east-1.amazonaws.com/092424_DOUBLE-Arrows_UP_DOWN_LtBrwn_UP.png"
          alt="Up Arrow"
          className="mq3-indicator-arrow-up"
        />
      );
    } else if (perc < 0) {
      return (
        <img
          src="https://pma-coverphotos.s3.us-east-1.amazonaws.com/092424_DOUBLE-Arrows_UP_DOWN_LtBrwn_DOWN.png"
          alt="Down Arrow"
          className="mq3-indicator-arrow-down"
        />
      );
    } else if (perc === 0) {
      return <div className="indicator-balanced">No Change</div>;
    }
  };

  // formatArrowOriginal = (perc, category) => {
  //   perc = +perc;
  //   const { mlsAreaID } = this.props.currentArea;
  //   if (perc > 0) {
  //     //  console.log("category", category);
  //
  //     if (
  //       category === "Sale Prices" ||
  //       category === "Square Ft" ||
  //       category === "% Received of Asking" ||
  //       category === "Days to Sell"
  //     ) {
  //       //console.log("sale prices", value);
  //       return (
  //         <img
  //           src="https://pma-coverphotos.s3.amazonaws.com/non-covers/double-violet-up.png"
  //           alt="Up Arrow"
  //           className="mq3-indicator-arrow-up"
  //         />
  //       );
  //     } else {
  //       return (
  //         <img
  //           src="https://pma-coverphotos.s3.amazonaws.com/non-covers/double-violet-down.png"
  //           alt="Up Arrow"
  //           className="mq3-indicator-arrow-up"
  //         />
  //       );
  //     }
  //   } else if (perc < 0) {
  //     if (
  //       category === "Sale Prices" ||
  //       category === "Square Ft" ||
  //       category === "% Received of Asking"
  //     ) {
  //       return (
  //         <img
  //           src="https://pma-coverphotos.s3.amazonaws.com/non-covers/double-violet-down.png"
  //           alt="Down Arrow"
  //           className="mq3-indicator-arrow-down"
  //         />
  //       );
  //     } else {
  //       return (
  //         <img
  //           src="https://pma-coverphotos.s3.amazonaws.com/non-covers/double-violet-down.png"
  //           alt="Down Arrow"
  //           className="mq3-indicator-arrow-down"
  //         />
  //       );
  //     }
  //   } else if (perc === 0) {
  //     return <div className="indicator-balanced">No Change</div>;
  //   }
  // };
  render() {
    const { category, value, perc } = this.props;
    const { formatCategory, formatValue, formatArrow } = this;
    const { posOrNeg, infinityCheck } = propertyUtil;
    return (
      <div className="indicator-section">
        <div className="indicator-title-container">
          {formatCategory(category)}
          <p className="indicator-value">{formatValue(value)}</p>
          <div className="indicator-title-bottom-border" />
        </div>
        <div className="indicator-col-1">
          {perc == "0" ? (
            ""
          ) : (
            <p className="indicator-perc">{posOrNeg(perc, null, true)}%</p>
          )}

          {formatArrow(perc, category)}
        </div>
      </div>
    );
  }
}

export default IndicatorSection;
