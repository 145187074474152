// import React, { Component } from "react";
// import QVFourPhotoSection from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPhotoSection";
// import QVFourPresentedByFooter from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPresentedByFooter";
// import AgentLogoHeader from "views/Pma/PmaEditor/ThirdPageComponents/AgentLogoHeader";
// import QVFourPS from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPS";
// import QVFourPhotos from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPhotos";
// import YETwoShortenedMarketTable from "views/Pma/PmaEditor/YearEnd2022/YETwo/YETwoSecondPage/YETwoShortenedMarketTable";
// import MQThreeAvailableHomes from "views/Pma/PmaEditor/MidQuarter/MQThree/MQThreeSecondPage/MQThreeAvailableHomes";
// import QRCode from "views/Pma/PmaEditor/ThirdPageComponents/QRCode";
//
// class YETwoSecondRight extends Component {
//   sortByStatus = (propList, getExtendedTable) => {
//     const {
//       activePhotoDetailsList,
//       contingentPhotoDetailsList,
//       pendingPhotoDetailsList,
//       preSoldPhotoDetailsList,
//       recentSoldPhotoDetailsList,
//       agentFilteredPhotoDetailsList,
//     } = this.props.quarterlyAreaTable;
//
//     let sliced = propList
//       .slice(0, 19)
//       .sort((a, b) => new Date(b.saleDate) - new Date(a.saleDate));
//     let sold = recentSoldPhotoDetailsList ? recentSoldPhotoDetailsList : [];
//     let pend = pendingPhotoDetailsList ? pendingPhotoDetailsList : [];
//     let cont = contingentPhotoDetailsList ? contingentPhotoDetailsList : [];
//     let active = activePhotoDetailsList ? activePhotoDetailsList : [];
//     let laterSold = preSoldPhotoDetailsList ? preSoldPhotoDetailsList : [];
//     let agentsProps = agentFilteredPhotoDetailsList
//       ? agentFilteredPhotoDetailsList
//       : [];
//     let last45DaysSum =
//       (agentFilteredPhotoDetailsList ? agentFilteredPhotoDetailsList : 0) +
//       (activePhotoDetailsList ? activePhotoDetailsList.length : 0) +
//       (contingentPhotoDetailsList ? contingentPhotoDetailsList.length : 0) +
//       (pendingPhotoDetailsList ? pendingPhotoDetailsList.length : 0) +
//       (recentSoldPhotoDetailsList ? recentSoldPhotoDetailsList.length : 0);
//
//     let remainderPreSold = 12 - last45DaysSum;
//
//     // let sold = sliced.filter((x) => x.status === "Sold");
//     // let pend = sliced.filter((x) => x.status === "Pending");
//     // let cont = sliced.filter((x) => x.status === "Contingent");
//     // let active = sliced.filter((x) => x.status === "Active");
//     let trueSort = [
//       ...agentsProps,
//       ...active,
//       ...pend,
//       ...cont,
//       ...sold,
//       ...laterSold.slice(0, remainderPreSold),
//     ];
//     if (last45DaysSum < 12) {
//       //  console.log("truesort", trueSort);
//       if (getExtendedTable) {
//         return false;
//       } else {
//         return trueSort;
//       }
//     } else {
//       let today = new Date();
//       let date45DaysAgo = new Date(
//         today.setDate(today.getDate() - 45)
//       ).toISOString();
//
//       let last45DayDates = trueSort.filter(
//         (x) =>
//           (x.saleDate !== "-" && x.saleDate > date45DaysAgo) ||
//           x.status == "Active" ||
//           x.status === "Contingent" ||
//           x.status === "Pending"
//       );
//       let remainderExtended = 18 - last45DayDates.length;
//
//       let extendedTrueSort = [
//         ...agentsProps,
//         ...active,
//         ...pend,
//         ...cont,
//         ...sold,
//         ...laterSold.slice(0, remainderExtended),
//       ];
//
//       if (getExtendedTable) {
//         return true;
//       } else {
//         return extendedTrueSort;
//       }
//     }
//     // return propList.slice(0, 19);
//   };
//   render() {
//     const {
//       currentArea,
//       agentData,
//       printVersion,
//       version,
//       hoData,
//       mlsAreas,
//       aprilData,
//       demoAccountHOName,
//       propertyPhotoDetailsDTO,
//       oldPhotoDetails,
//       photoTableData,
//       quarterlyAreaTable,
//       quarterlyCityTable,
//       MQCityDetails,
//       isDemo,
//     } = this.props;
//     console.log("inside qv4 second right");
//     const { mlsAreaID } = currentArea;
//     const { currentSoldListings } = quarterlyAreaTable;
//     //  console.log("currentSoldListings", currentSoldListings);
//     let sorted =
//       currentSoldListings && currentSoldListings
//         ? this.sortByStatus(currentSoldListings, false)
//         : [];
//     let useExtendedTable =
//       currentSoldListings && currentSoldListings
//         ? this.sortByStatus(currentSoldListings, true)
//         : [];
//
//     let isSame = photoTableData === currentSoldListings;
//
//     let useCity = false;
//     if (mlsAreaID === "SBA-40F" || mlsAreaID === "SBA-40G") {
//       useCity = true;
//     }
//     //console.log('qr')
//     if (mlsAreaID.includes("SBA")) {
//       return (
//         <div className="qv4-second-right">
//           <AgentLogoHeader agentData={agentData} currentArea={currentArea}/>
//           {hoData &&
//             hoData.changeDetails &&
//             Object.keys(hoData.changeDetails).length > 1 && (
//               <QVFourPS
//                 agentData={agentData}
//                 hoData={hoData}
//                 currentArea={currentArea}
//                 mlsAreas={mlsAreas}
//                 version={version}
//                 printVersion={printVersion}
//                 mailingNum="qv4"
//                 demoAccountHOName={demoAccountHOName}
//                 isDemo={isDemo}
//               />
//             )}
//           <QRCode hoData={hoData} agentData={agentData} smallerQR={true} />
//           <QVFourPhotoSection
//             currentArea={currentArea}
//             agentData={agentData}
//             printVersion={printVersion}
//             version={version}
//             hoData={hoData}
//             currentSoldListings={currentSoldListings}
//           />
//           <QVFourPresentedByFooter
//             agentData={agentData}
//             currentArea={currentArea}
//             currentSoldListings={currentSoldListings}
//           />
//         </div>
//       );
//     } else {
//       return (
//         <div className="qv4-second-right">
//           <AgentLogoHeader agentData={agentData} currentArea={currentArea}/>
//           {hoData &&
//             hoData.changeDetails &&
//             Object.keys(hoData.changeDetails).length > 1 && (
//               <QVFourPS
//                 agentData={agentData}
//                 hoData={hoData}
//                 currentArea={currentArea}
//                 mlsAreas={mlsAreas}
//                 version={version}
//                 printVersion={printVersion}
//                 mailingNum="qv4"
//                 demoAccountHOName={demoAccountHOName}
//                 isDemo={isDemo}
//               />
//             )}
//           <QRCode hoData={hoData} agentData={agentData} />
//           <YETwoShortenedMarketTable
//             quarterlyCityTable={quarterlyCityTable}
//             currentArea={currentArea}
//             quarterlyAreaTable={
//               useCity ? quarterlyCityTable : quarterlyAreaTable
//             }
//             useCity="false"
//             hoData={hoData}
//             agentData={agentData}
//             MQCityDetails={MQCityDetails}
//             lastMidQuarterlyPrintDate={
//               quarterlyAreaTable.lastMidQuarterlyPrintDate
//             }
//             //printDate="Nov 1, 2021"
//             printVersion={printVersion}
//             currentSoldListings={currentSoldListings}
//             sorted={sorted}
//             useExtendedTable={useExtendedTable}
//           />
//           {!useExtendedTable && (
//             <MQThreeAvailableHomes
//               currentArea={currentArea}
//               quarterlyAreaTable={quarterlyAreaTable}
//               quarterlyCityTable={quarterlyCityTable}
//               MQCityDetails={MQCityDetails}
//               hoData={hoData}
//               agentData={agentData}
//               useCity={useCity}
//               altVersion="ye2"
//printVersion={printVersion}
//             />
//           )}
//
//           <QVFourPresentedByFooter
//             agentData={agentData}
//             currentArea={currentArea}
//             currentSoldListings={currentSoldListings}
//           />
//         </div>
//       );
//     }
//   }
// }
//
// export default YETwoSecondRight;
import React, { Component } from "react";
import AgentLogoHeader from "views/Pma/PmaEditor/ThirdPageComponents/AgentLogoHeader";
import QVFourPhotos from "./QVFourPhotos";
import QVFourPresentedByFooter from "views/Pma/PmaEditor/ThirdPageComponents/QVFourPresentedByFooter";
import QVFourPhotoSection from "./QVFourPhotoSection";
import QVFourPS from "./QVFourPS";
import TestPhoto2 from "./TestPhoto2";
import QRCode from "views/Pma/PmaEditor/ThirdPageComponents/QRCode";
import YETwoShortenedMarketTable from "views/Pma/PmaEditor/YearEnd2022/YETwo/YETwoSecondPage/YETwoShortenedMarketTable";
import MQThreeAvailableHomes from "views/Pma/PmaEditor/MidQuarter/MQThree/MQThreeSecondPage/MQThreeAvailableHomes";

class QVFourSecondRight extends Component {
  sortByStatus = (propList, getExtendedTable) => {
    const {
      activePhotoDetailsList,
      contingentPhotoDetailsList,
      pendingPhotoDetailsList,
      preSoldPhotoDetailsList,
      recentSoldPhotoDetailsList,
      agentFilteredPhotoDetailsList,
    } = this.props.quarterlyAreaTable;
    let sliced = propList
      .slice(0, 19)
      .sort((a, b) => new Date(b.saleDate) - new Date(a.saleDate));
    let sold = recentSoldPhotoDetailsList ? recentSoldPhotoDetailsList : [];
    let pend = pendingPhotoDetailsList ? pendingPhotoDetailsList : [];
    let cont = contingentPhotoDetailsList ? contingentPhotoDetailsList : [];
    let active = activePhotoDetailsList ? activePhotoDetailsList : [];
    let laterSold = preSoldPhotoDetailsList ? preSoldPhotoDetailsList : [];
    let agentsProps = agentFilteredPhotoDetailsList
      ? agentFilteredPhotoDetailsList
      : [];
    let last45DaysSum =
      (agentFilteredPhotoDetailsList ? agentFilteredPhotoDetailsList : 0) +
      (activePhotoDetailsList ? activePhotoDetailsList.length : 0) +
      (contingentPhotoDetailsList ? contingentPhotoDetailsList.length : 0) +
      (pendingPhotoDetailsList ? pendingPhotoDetailsList.length : 0) +
      (recentSoldPhotoDetailsList ? recentSoldPhotoDetailsList.length : 0);

    let remainderPreSold = 12 - last45DaysSum;
    console.log("***agentsProps", agentsProps);
    // let sold = sliced.filter((x) => x.status === "Sold");
    // let pend = sliced.filter((x) => x.status === "Pending");
    // let cont = sliced.filter((x) => x.status === "Contingent");
    // let active = sliced.filter((x) => x.status === "Active");
    let trueSort = [
      ...agentsProps,
      ...active,
      ...pend,
      ...cont,
      ...sold,
      ...laterSold.slice(0, remainderPreSold),
    ];
    if (last45DaysSum < 12) {
      if (getExtendedTable) {
        return false;
      } else {
        return trueSort;
      }
    } else {
      let today = new Date();
      let date45DaysAgo = new Date(
        today.setDate(today.getDate() - 45)
      ).toISOString();

      let last45DayDates = trueSort.filter(
        (x) =>
          (x.saleDate !== "-" && x.saleDate > date45DaysAgo) ||
          x.status == "Active" ||
          x.status === "Contingent" ||
          x.status === "Pending"
      );
      let remainderExtended = 18 - last45DayDates.length;

      let extendedTrueSort = [
        ...agentsProps,
        ...active,
        ...pend,
        ...cont,
        ...sold,
        ...laterSold.slice(0, remainderExtended),
      ];

      if (getExtendedTable) {
        return true;
      } else {
        return extendedTrueSort;
      }
    }
    // return propList.slice(0, 19);
  };
  render() {
    const {
      currentArea,
      agentData,
      printVersion,
      version,
      hoData,
      mlsAreas,
      aprilData,
      demoAccountHOName,
      propertyPhotoDetailsDTO,
      oldPhotoDetails,
      photoTableData,
      quarterlyAreaTable,
      quarterlyCityTable,
      MQCityDetails,
      isDemo,
    } = this.props;
    const { listgenUserID } = agentData;
    const { mlsAreaID } = currentArea;
    let useCity = false;
    if (mlsAreaID === "SBA-40F" || mlsAreaID === "SBA-40G") {
      useCity = true;
    }
    const { currentSoldListings } = quarterlyAreaTable;
    let sorted =
      currentSoldListings && currentSoldListings
        ? this.sortByStatus(currentSoldListings, false)
        : [];
    let useExtendedTable =
      currentSoldListings && currentSoldListings
        ? this.sortByStatus(currentSoldListings, true)
        : [];

    let isSame = photoTableData === currentSoldListings;

    //console.log("currentSoldListings", quarterlyAreaTable.currentSoldListings);
    //console.log("second right", this.props);
    return (
      <div className="qv4-second-right">
        <AgentLogoHeader agentData={agentData} currentArea={currentArea} />
        <QVFourPS
          agentData={agentData}
          hoData={hoData}
          currentArea={currentArea}
          mlsAreas={mlsAreas}
          version={version}
          printVersion={printVersion}
          mailingNum="qv4"
          isDemo={isDemo}
        />
        {listgenUserID === "103" && (
          <QRCode hoData={hoData} agentData={agentData} smallerQR={true} />
        )}
        {/*<QVFourPhotos
          currentArea={currentArea}
          agentData={agentData}
          printVersion={printVersion}
          version={version}
          hoData={hoData}
          currentSoldListings={quarterlyAreaTable.currentSoldListings}
        />*/}
        {version === "ye2" && <QRCode hoData={hoData} agentData={agentData} />}
        {listgenUserID === "103" ? (
          <YETwoShortenedMarketTable
            quarterlyCityTable={quarterlyCityTable}
            currentArea={currentArea}
            quarterlyAreaTable={
              useCity ? quarterlyCityTable : quarterlyAreaTable
            }
            useCity="false"
            hoData={hoData}
            agentData={agentData}
            MQCityDetails={MQCityDetails}
            lastMidQuarterlyPrintDate={
              quarterlyAreaTable.lastMidQuarterlyPrintDate
            }
            //printDate="Nov 1, 2021"
            printVersion={printVersion}
            currentSoldListings={currentSoldListings}
            sorted={sorted}
            useExtendedTable={useExtendedTable}
          />
        ) : (
          <QVFourPhotoSection
            currentArea={currentArea}
            agentData={agentData}
            printVersion={printVersion}
            version={version}
            hoData={hoData}
            currentSoldListings={quarterlyAreaTable.currentSoldListings}
          />
        )}
        {listgenUserID === "103" && (
          <MQThreeAvailableHomes
            currentArea={currentArea}
            quarterlyAreaTable={quarterlyAreaTable}
            quarterlyCityTable={quarterlyCityTable}
            MQCityDetails={MQCityDetails}
            hoData={hoData}
            agentData={agentData}
            useCity={useCity}
            altVersion="ye2"
            printVersion={printVersion}
          />
        )}
        {/*<TestPhoto2
          currentArea={currentArea}
          agentData={agentData}
          printVersion={printVersion}
          version={version}
          hoData={hoData}
          currentSoldListings={quarterlyAreaTable.currentSoldListings}
        />*/}
        <QVFourPresentedByFooter
          agentData={agentData}
          currentArea={currentArea}
          currentSoldListings={quarterlyAreaTable.currentSoldListings}
          printVersion={printVersion}
        />
      </div>
    );
  }
}

export default QVFourSecondRight;
