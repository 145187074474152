import React, { Component } from "react";
import BeigeImg from "./091024_PMA_MissionStmt_imageBG_3.jpg";
import { Link } from "react-router-dom";

class MissionStatementBlock extends Component {
  componentDidMount() {
    if (this.props.printVersion) {
      require("./print.scss");
    } else {
      require("./editor.scss");
    }
  }
  render() {
    const {
      printVersion,
      agentData,
      missionStatement,
      currentArea,
    } = this.props;
    let { missionStatementContent, missionStatementHeader } = missionStatement;
    const { mlsAreaID, mlsNeighborhood } = currentArea;
    let { photoURL, isMemberOfTeam, listgenUserID } = agentData;
    if (missionStatementContent.includes("\n")) {
      missionStatementContent = missionStatementContent.replace(
        "\n",
        "<br /><br />"
      );
    }

    let hasPhotoBorder = false;
    let missionStatementTextStyles;
    if (listgenUserID === "100661") {
      photoURL =
        "https://pma-coverphotos.s3.us-east-1.amazonaws.com/kelly-mahan-headshot.jpg";
    } else if (listgenUserID === "100656") {
      photoURL =
        "https://pma-coverphotos.s3.amazonaws.com/non-covers/091624_LisaMcCollum_missionStatmt.jpg";
    } else if (listgenUserID === "105790") {
      photoURL =
        "https://pma-coverphotos.s3.amazonaws.com/non-covers/091924_gary_missionStatmt_1.jpg";
    } else if (mlsAreaID === "SCL-205A") {
      photoURL =
        "https://pma-coverphotos.s3.amazonaws.com/non-covers/091424_CalebF-LynneM_combo-Headshot.png";
    } else if (listgenUserID === "102712") {
      photoURL =
        "https://pma-coverphotos.s3.amazonaws.com/non-covers/022524_LilaKazemi_Headshot.jpg";
    } else if (listgenUserID === "106450") {
      photoURL =
        "https://pma-coverphotos.s3.amazonaws.com/non-covers/GretaSieglock_MissionStatemt.png";
      missionStatementTextStyles = "mission-statement-14-5";
    } else if (listgenUserID === "100040") {
      photoURL =
        "https://pma-coverphotos.s3.us-east-1.amazonaws.com/amber-russell_mission-statement_2.jpg";
    } else if (listgenUserID === "102843") {
      photoURL =
        "https://pma-coverphotos.s3.us-east-1.amazonaws.com/galli-team-2.jpg";
    }
    if (photoURL && photoURL.includes(".jpg")) {
      hasPhotoBorder = true;
    }

    let useDefault = true;
    let pronoun = "My";
    if (isMemberOfTeam) {
      pronoun = "Our";
    }
    let missionTitleStyles = "";
    let missionContentStyles = "";
    if (missionStatementHeader && missionStatementHeader.length > 40) {
      missionTitleStyles = "mission-statement-title-26";
    }

    if (missionStatementContent && missionStatementContent.length < 280) {
      missionStatementTextStyles = "mission-statement-content-20";
    }

    if (
      listgenUserID === "102843" &&
      missionStatementContent &&
      missionStatementContent.length >= 1 &&
      (missionStatementContent.includes("Cuesta Park") ||
        missionStatementContent.includes("Old Mountain View") ||
        missionStatementContent.includes("Shoreline West"))
    ) {
      missionStatementContent = missionStatementContent.replace(
        "Cuesta Park",
        mlsNeighborhood
      );
    }
    let useCustomPhoto = false;
    if (mlsAreaID === "SBA-5F") {
      //remove for march
      useCustomPhoto = true;
    }
    let missionColumnStyles = "";
    if (listgenUserID === "102843") {
      missionColumnStyles = "large-mission-statement-photo";
    }
    if (useCustomPhoto) {
      return (
        <div
          className={
            printVersion
              ? "print-mission-statement-block"
              : "editor-mission-statement-block"
          }
        >
          <img
            src="https://pma-coverphotos.s3.us-east-1.amazonaws.com/021425_PMA_D-B-CaseStudy_3_2.jpg"
            alt="custom photo"
            className="mission-statement-custom-photo"
          />
        </div>
      );
    } else if (useDefault) {
      return (
        <div
          className={
            printVersion
              ? "print-mission-statement-block"
              : "editor-mission-statement-block"
          }
        >
          {!printVersion && (
            <Link
              to={`/agent/missionstatements/${mlsAreaID}`}
              className="edit-button-mission-statement"
            >
              <p>Click Here to Edit</p>
              <p>Mission Statement</p>
            </Link>
          )}

          <div className="mission-statement-block-bg-container">
            <img
              className="mission-statement-block-bg"
              src="https://pma-coverphotos.s3.us-east-1.amazonaws.com/021325_FEB-PMA_MissionStmt_3.jpg"
            />
            <div className="mission-statement-content-container">
              <div
                className={`mission-statement-column-container ${missionColumnStyles}`}
              >
                <div className="mission-statement-image-container">
                  <img
                    src={photoURL}
                    className={`mission-statement-image ${
                      hasPhotoBorder && "mission-photo-border"
                    }`}
                  />
                </div>
              </div>

              <div className="mission-statement-inner-container">
                <div className="mission-statement-text-container">
                  {missionStatementHeader ? (
                    <div
                      className={`mission-statement-title ${missionTitleStyles}`}
                    >
                      {missionStatementHeader}
                    </div>
                  ) : (
                    <div
                      className={`mission-statement-title ${missionTitleStyles}`}
                    >
                      {pronoun} Commitment to You
                    </div>
                  )}

                  <div
                    className={`mission-statement-text ${missionStatementTextStyles}`}
                    dangerouslySetInnerHTML={{
                      __html: missionStatementContent,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={
            printVersion
              ? "print-mission-statement-block-v2"
              : "editor-mission-statement-block-v2"
          }
        >
          {!printVersion && (
            <Link
              to="/agent/missionstatements"
              className="edit-button-mission-statement"
            >
              <p>Click Here to Edit</p>
              <p>Mission Statement</p>
            </Link>
          )}
          <div className="mission-statement-block-bg-container">
            <img
              className="mission-statement-block-bg"
              src="https://pma-coverphotos.s3.us-east-1.amazonaws.com/021325_FEB-PMA_MissionStmt_3.jpg"
            />
            <div className="mission-statement-content-container">
              <div className="mission-statement-inner-container">
                <div className="mission-statement-image-container">
                  <img
                    src={photoURL}
                    className={`mission-statement-image ${
                      hasPhotoBorder && "mission-photo-border"
                    }`}
                  />
                </div>

                <div className="mission-statement-text-container">
                  {missionStatementHeader ? (
                    <div
                      className={`mission-statement-title ${missionTitleStyles}`}
                    >
                      {missionStatementHeader}
                    </div>
                  ) : (
                    <div
                      className={`mission-statement-title ${missionTitleStyles}`}
                    >
                      {pronoun} Commitment to You
                    </div>
                  )}
                  <div
                    className={`mission-statement-text ${missionStatementTextStyles}`}
                    dangerouslySetInnerHTML={{
                      __html: missionStatementContent,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default MissionStatementBlock;
