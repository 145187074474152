import React, { Component } from "react";
import TopAgentInfoAndAddress from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/TopAgentInfoAndAddress";
import BackPageImageContainer from "views/Pma/PmaEditor/CustomAgentPages/BackPageImageContainer";
import JohnsonsQuadrantsTwo from "views/Pma/PmaEditor/CustomAgentPages/JohnsonsQuadrantsTwo";
import GailPearlCustomHighlight from "views/Pma/PmaEditor/YearEnd2022/YETwo/YETwoFirstPage/GailPearlCustomHighlight";
import YETwoPropertyHighlights from "views/Pma/PmaEditor/YearEnd2022/YETwo/YETwoFirstPage/YETwoPropertyHighlights";
import { propertyUtil } from "views/Pma/PropertyUtil";

import BackpageBoxes from "views/Pma/PmaEditor/Quarterly/QVThree/QVThreeFirstPage/BackpageBoxes";
import AnnetteQuadrants from "views/Pma/PmaEditor/CustomAgentPages/AnnetteQuadrants";

import QVFourTestimonial from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourTestimonial";
import QuadrantContainer from "views/Pma/PmaEditor/FourthPageComponents/QuadrantContainer";
import FiveQuadrantContainer from "views/Pma/PmaEditor/FourthPageComponents/FiveQuadrantContainer";

class FirstPageComponents extends Component {
  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,
      currentArea,
      mlsAreas,

      coverPageDetails,

      testimonials,
      handlePropertyHighlightDialog,
      propertyPhotoDetailsDTO,
      quarterlyAreaTable,
      bubbleBoxes,
      quadrantDetails,
      quadrantModalOpen,
      isDemo,
      sortSelected,
      hasTitle,
      propertyHighlightsTitle,
      hasSubtitle,
      propertyHighlightsSubtitle,
      hasFooterText1,
      propertyHighlightsFooterText1,
      hasFooterText2,
      propertyHighlightsFooterText2,

      useBackpageBoxes,
    } = this.props;
    let { useQuadrants } = this.props;
    const { mlsAreaID } = currentArea;
    const { listgenUserID } = agentData;
    let isAnnetteVait = listgenUserID === "x101001";
    let isJohnsons = listgenUserID === "x100988";
    let isKateryna = listgenUserID === "x102286" || listgenUserID === "x105047";

    if (
      listgenUserID === "100661" &&
      propertyPhotoDetailsDTO.photoDetails &&
      propertyPhotoDetailsDTO.photoDetails.length >= 1
    ) {
      useQuadrants = false;
    }

    return (
      <div className="ye2-first-left">
        <TopAgentInfoAndAddress
          currentArea={currentArea}
          printVersion={printVersion}
          agentData={agentData}
          isDemo={isDemo}
          hoData={hoData}
          version={version}
          coverPageDetails={coverPageDetails}
          quadrantModalOpen={quadrantModalOpen}
          mlsAreaID={mlsAreaID}
        />
        {listgenUserID === "x106421" || listgenUserID === "x105224" ? (
          <BackPageImageContainer agentData={agentData} />
        ) : isJohnsons ? (
          <JohnsonsQuadrantsTwo />
        ) : isAnnetteVait ? (
          <AnnetteQuadrants />
        ) : listgenUserID === "x103455" ? (
          <GailPearlCustomHighlight
            currentSoldListings={sortSelected}
            handlePropertyHighlightDialog={handlePropertyHighlightDialog}
            quarterlyAreaTable={quarterlyAreaTable}
            agentData={agentData}
            printVersion={printVersion}
            currentArea={currentArea}
            title={hasTitle ? propertyHighlightsTitle : ""}
            subtitle={hasSubtitle ? propertyHighlightsSubtitle : ""}
            footerText1={hasFooterText1 ? propertyHighlightsFooterText1 : ""}
            footerText2={hasFooterText2 ? propertyHighlightsFooterText2 : ""}
            mlsAreaID={currentArea.mlsAreaID}
          />
        ) : useQuadrants ? (
          <>
            <FiveQuadrantContainer
              currentArea={currentArea}
              quadrantDetails={quadrantDetails}
              mlsAreas={mlsAreas}
              printVersion={printVersion}
              testimonials={testimonials}
              hoData={hoData}
            />
          </>
        ) : useBackpageBoxes ? (
          <BackpageBoxes
            currentArea={currentArea}
            agentData={agentData}
            bubbleBoxes={bubbleBoxes}
            mlsAreas={currentArea.mlsAreas}
            hoData={hoData}
            printVersion={printVersion}
          />
        ) : propertyPhotoDetailsDTO &&
          Object.keys(propertyPhotoDetailsDTO).length > 1 ? (
          <YETwoPropertyHighlights
            currentSoldListings={sortSelected}
            handlePropertyHighlightDialog={handlePropertyHighlightDialog}
            quarterlyAreaTable={quarterlyAreaTable}
            agentData={agentData}
            printVersion={printVersion}
            currentArea={currentArea}
            title={hasTitle ? propertyHighlightsTitle : ""}
            subtitle={hasSubtitle ? propertyHighlightsSubtitle : ""}
            footerText1={hasFooterText1 ? propertyHighlightsFooterText1 : ""}
            footerText2={hasFooterText2 ? propertyHighlightsFooterText2 : ""}
            mlsAreaID={currentArea.mlsAreaID}
            testimonials={testimonials}
          />
        ) : (
          <div className="no-property-highlights">
            <div className="no-property-highlights-text">
              We are currently working on your featured properties page. This
              page will allow you to showcase up 5 properties. Please stay
              tuned!
            </div>
          </div>
        )}
        {/*{sortSelected && sortSelected.length === 2 && (
          <QVFourTestimonial
            testimonials={testimonials}
            agentData={agentData}
            printVersion={printVersion}
          />
        )}*/}
      </div>
    );
  }
}

export default FirstPageComponents;
