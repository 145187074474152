import React, { Component } from "react";
import CoverPage from "views/Pma/PmaEditor/FirstPageComponents/CoverPage";

class MQThreeFirstRight extends Component {
  render() {
    const {
      agentData,
      hoData,
      currentArea,
      coverPageDetails,
      version,
      MQCityDetails,
      quarterlyAreaTable,
      demoAccountAreaName,
      demoAccountCoverPhoto,
      printVersion,
    } = this.props;

    return (
      <div className="mq3-first-right">
        <CoverPage
          agentData={agentData}
          coverPageDetails={coverPageDetails}
          currentArea={currentArea}
          hoData={hoData}
          MQCityDetails={MQCityDetails}
          quarterlyAreaTable={quarterlyAreaTable}
          demoAccountAreaName={demoAccountAreaName}
          demoAccountCoverPhoto={demoAccountCoverPhoto}
          photoNum="1"
          currentSoldListings={quarterlyAreaTable.currentSoldListings}
          marketHighLightsData={quarterlyAreaTable.marketHighLightsData}
          printVersion={printVersion}
          version={version}
        />
      </div>
    );
  }
}

export default MQThreeFirstRight;
