import React, { Component } from "react";
import CoverPage from "views/Pma/PmaEditor/FirstPageComponents/CoverPage";

class QVTwoFirstRight extends Component {
  render() {
    const {
      agentData,
      hoData,
      currentArea,
      coverPageDetails,
      version,
      MQCityDetails,
      quarterlyAreaTable,
      aprilData,
      printVersion,
    } = this.props;

    return (
      <div className="qv2-first-right">
        <CoverPage
          agentData={agentData}
          coverPageDetails={coverPageDetails}
          currentArea={currentArea}
          hoData={hoData}
          photoNum="1"
          quarterlyAreaTable={aprilData.pmaQuarterlyAreaDTO}
          currentSoldListings={
            aprilData.pmaQuarterlyAreaDTO.currentSoldListings
          }
          marketHighLightsData={
            aprilData.pmaQuarterlyAreaDTO.marketHighLightsData
          }
          // printDate="July 6"
          printVersion={printVersion}
          version={version}
        />
      </div>
    );
  }
}

export default QVTwoFirstRight;
