import React, { Component, Fragment } from "react";
import { withRouter, Link, NavLink } from "react-router-dom";
import classNames from "classnames";
import compose from "recompose/compose";
import PropTypes from "prop-types";
import { Divider, withStyles } from "@material-ui/core";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Base from "common/constants";

import {
  Badge,
  IconButton,
  Popover,
  Toolbar,
  Typography,
  AppBar,
  Button,
} from "@material-ui/core";

import {
  Menu as MenuIcon,
  Close as CloseIcon,
  AccountBoxOutlined as AccountIcon,
  LockOpen as LogoutIcon,
  DriveEta,
} from "@material-ui/icons";

import styles from "./styles";
import {
  IsValidLoginAuth,
  IsValidSemiAuth,
  GetStateStorage,
} from "common/storage";
import HelpV1Wrapper from "views/Help/HelpV1Wrapper";
import { isUserAgent } from "common/roles/role";

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.node.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  window: PropTypes.func,
};

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltip: {
        account: false,
        openScheduleDialog: false,
      },
    };
    this.signal = true;
    /** check for auth */
    this.isValidLogin = IsValidLoginAuth();
    this.isValidSemiLogin = IsValidSemiAuth();
    this.myAccountMode = "0";
    if (this.isValidSemiLogin) {
      this.myAccountMode = "1";
    } else if (this.isValidLogin) {
      this.myAccountMode = "2";
    }
  }

  handleTooltipClose = (field) => {
    const newState = { ...this.state };
    newState.tooltip[field] = false;
    this.setState(newState);
  };

  handleTooltipOpen = (field) => {
    const newState = { ...this.state };
    newState.tooltip[field] = true;
    this.setState(newState);
  };

  openLoginPage = () => {
    const { history } = this.props;
    history.push("/login");
  };

  handleAccountButtonLink = (uri) => {
    const { history } = this.props;
    history.push(uri);
  };

  openScheduleDialog = () => {
    this.setState({
      openScheduleDialog: true,
    });
  };

  closeScheduleDialog = () => {
    this.setState({
      openScheduleDialog: false,
    });
  };

  getAccountOption = () => {
    const { classes, localStateData } = this.props;
    let listgenUserFirstName =
      localStateData &&
      localStateData.userData &&
      localStateData.userData.listgenUserFirstName;

    if (this.myAccountMode === "0") {
      //not in use as of nwo because layout is not used on login screen
      //not logged in
      return (
        <IconButton
          className={classNames(classes.accountButton)}
          color="inherit"
          onClick={(event) => this.handleAccountButtonLink("/logout")}
        >
          <Link to="/login">
            <Typography variant="subtitle2" component="h6">
              My Account
            </Typography>
          </Link>
        </IconButton>
      );
    } else if (this.myAccountMode === "1") {
      //semo login
      return (
        <IconButton
          className={classNames(classes.accountButton)}
          color="inherit"
          onClick={(event) => this.handleAccountButtonLink("/logout")}
        >
          <LogoutIcon />
          &nbsp;
          <Typography variant="subtitle2" component="h6">
            Logout
          </Typography>
        </IconButton>
      );
    } else if (this.myAccountMode === "2") {
      //show only on valid login. show if wem have name
      return (
        <Fragment>
          {!this.props.isMobile && listgenUserFirstName ? (
            <Fragment>
              <Typography
                variant="subtitle1"
                component="h6"
                color="primary"
                className={classes.accountNameMain}
              >
                Hi, {listgenUserFirstName}!
              </Typography>
              <Divider
                orientation="vertical"
                flexItem
                className={classes.accountNameDivider}
              />
            </Fragment>
          ) : (
            ""
          )}
          <IconButton
            className={classNames(classes.accountButton)}
            color="inherit"
            onClick={(event) => this.handleAccountButtonLink("/account")}
          >
            <AccountIcon />
            &nbsp;
            <Typography variant="subtitle2" component="h6">
              My Account
            </Typography>
          </IconButton>
        </Fragment>
      );
    }
  };

  render() {
    const {
      classes,
      className,
      gradientMode,
      isMobile,
      onToggleSidebar,
      isSidebarOpen,
      localStateData,
    } = this.props;
    const stateData = GetStateStorage();
    const agentData = (stateData || {}).userData;
    let navbarData =
      agentData && Array.isArray(agentData.navbarInfo)
        ? agentData.navbarInfo[0]
        : {};

    const listgenLogo = "/images/listgen-logo.png";
    const listgenAltLogo = "/images/listgen-text-advanced-v1.png";
    const listgenLogoAlt = "ListGen Logo";

    const rootClassName = classNames(classes.root, className);

    return (
      <Fragment>
        <div className={rootClassName}>
          <ElevationScroll {...this.props}>
            <AppBar
              color="default"
              className={gradientMode ? classes.appBar : ""}
            >
              <Toolbar className={classes.toolbar}>
                {isMobile ? (
                  <IconButton
                    className={classes.menuButton}
                    classes={{ label: classes.menuButtonLabel }}
                    onClick={onToggleSidebar}
                    variant="text"
                    color="inherit"
                    aria-label="Menu"
                  >
                    <MenuIcon />
                    <span>Menu</span>
                  </IconButton>
                ) : (
                  ""
                )}
                <div className={classes.grow}>
                  <div className={classes.inlineImageHolder}>
                    <Link to="/home">
                      <div className={classes.imageWrapper}>
                        <img
                          alt={listgenLogoAlt}
                          className={classes.image}
                          src={listgenLogo}
                        />
                      </div>
                    </Link>
                    <Link to="/home">
                      <div className={classes.imageWrapperAlt}>
                        <img
                          alt={listgenLogoAlt}
                          className={classes.image}
                          src={listgenAltLogo}
                        />
                      </div>
                    </Link>
                  </div>
                </div>
                {/* <Typography variant="h6" color="inherit" className={classes.grow}>
            ListGen
          <Typography variant="subtitle2" color="inherit" className={classes.grow}>
            REAL ESTATE ADVANCED
          </Typography>
          </Typography> */}
                {!isMobile ? (
                  <Fragment>
                    <div className={classes.middleMenus}>
                      <NavLink
                        to="/home"
                        activeClassName={classes.underlineButtonMenu}
                      >
                        <Button
                          color="default"
                          className={classNames(
                            classes.colorWhite,
                            classes.menuButtonMargin
                          )}
                        >
                          Home
                        </Button>
                      </NavLink>
                      {/* <NavLink to="/pricing" className={classes.leftMargin1} activeClassName={classNames(classes.underlineButtonMenu)}>
                        <Button color="default" className={classNames(classes.colorWhite)}>
                          Pricing
                        </Button>
                      </NavLink> */}
                      {/* <NavLink to="/about" className={classes.leftMargin1} activeClassName={classNames(classes.underlineButtonMenu)}>
                        <Button color="default" className={classes.colorWhite}>
                          About
                        </Button>
                      </NavLink> */}
                      {this.isValidLogin && !this.isValidSemiLogin ? (
                        <Fragment>
                          <NavLink
                            to="/legal"
                            className={classes.leftMargin1}
                            activeClassName={classNames(
                              classes.underlineButtonMenu
                            )}
                          >
                            <Button
                              color="default"
                              className={classes.colorWhite}
                            >
                              Legal Terms
                            </Button>
                          </NavLink>
                          {/* <NavLink
                            to="/schedule"
                            className={classes.leftMargin1}
                            activeClassName={classNames(classes.underlineButtonMenu, classes.leftMargin1)}
                          >
                            <Button color="default" className={classes.colorWhite}>
                              Mailing Schedule
                        </Button>
                          </NavLink> */}
                        </Fragment>
                      ) : (
                        ""
                      )}
                      {/* <NavLink
                        to="/help"
                        className={classes.leftMargin1}
                        activeClassName={classNames(
                          classes.underlineButtonMenu
                        )}
                      > */}
                      <HelpV1Wrapper>
                        <Button color="default" className={classes.colorWhite}>
                          Support
                        </Button>
                      </HelpV1Wrapper>
                      {/* </NavLink> */}
                      {!this.isValidLogin && this.isValidSemiLogin ? (
                        <NavLink
                          to="/agent/register"
                          className={classes.leftMargin1}
                          activeClassName={classNames(
                            classes.underlineButtonMenu
                          )}
                        >
                          <Button
                            color="default"
                            className={classes.colorWhite}
                          >
                            Register
                          </Button>
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {this.isValidLogin &&
                        !this.isValidSemiLogin &&
                        localStateData &&
                        localStateData.userRole &&
                        isUserAgent(localStateData.userRole) ? (
                        <NavLink
                          to={`/pma/${navbarData.mlsAreaID}`}
                          activeClassName={classNames(
                            classes.underlineButtonMenu,
                            classes.leftMargin1
                          )}
                        >
                          <Button
                            color="default"
                            className={classes.colorWhite}
                          >
                            View PMA
                          </Button>
                        </NavLink>
                      ) : (
                        ""
                      )}
                    </div>
                    {this.getAccountOption()}
                  </Fragment>
                ) : (
                  ""
                )}
                {isMobile ? this.getAccountOption() : ""}
              </Toolbar>
            </AppBar>
          </ElevationScroll>
        </div>
      </Fragment>
    );
  }
}

Topbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(withRouter, withStyles(styles))(Topbar);
