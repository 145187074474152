import React, { Component } from "react";
import CoverPage from "views/Pma/PmaEditor/FirstPageComponents/CoverPage";

class YETwoFirstRight extends Component {
  render() {
    const {
      agentData,
      coverPageDetails,
      currentArea,
      hoData,
      aprilData,
      printVersion,
      demoAccountCoverPhoto,
      demoAccountAreaName,
      quarterlyAreaTable,
      version,
      typeOfVersion,
    } = this.props;
    console.log("inside ye2 first right", this.props);
    const { currentSoldListings, marketHighLightsData } = quarterlyAreaTable;
    return (
      <div className="ye2-first-right">
        <CoverPage
          agentData={agentData}
          currentArea={currentArea}
          coverPageDetails={coverPageDetails}
          hoData={hoData}
          printVersion={printVersion}
          demoAccountCoverPhoto={demoAccountCoverPhoto}
          demoAccountAreaName={demoAccountAreaName}
          quarterlyAreaTable={quarterlyAreaTable}
          currentSoldListings={currentSoldListings}
          marketHighLightsData={marketHighLightsData}
          photoNum="1"
          version={version}
          only6Months={true}
          typeOfVersion="ye2"
          aprilData={aprilData}
          yearEnd={true}
          //printDate="October 4"
        />
      </div>
    );
  }
}

export default YETwoFirstRight;
