import React from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MailProButton from "../../UI/MailProButton";
import { getHONamesBaseDataClosureV2, getHONamesOriginalDataClosureV2 } from "../../MailProHTMLShortMethods";
import { propertyUtil } from "views/Pma/PropertyUtil";
import { renderLogicUtil } from "views/Pma/RenderLogicUtil";
import EditHomeownerDialogV2 from "../../EditHomeownerDialogV2";
import EditTagsDialog from "../../EditTagsDialog";
import BlockPropertyDialog from "../../BlockPropertyDialog";
import classNames from "classnames";
import { pmaActions } from "_store/_actions";
import { withSnackbar } from "notistack";
import { compose } from "recompose";
import { connect } from "react-redux";
import { CommonConfirmationDialogV2Component } from "components";

const LGWhiteLogo = "../../images/lg_white_logo.png";

const AnniversaryAutomatorDialogHeader = ({
  classes,
  property,
  propertySequenceNumber,
  agentData,
  propertyData,
  isMailProLocked,
  anniversarySkippableMailProIdsCount,
  ...rest
}) => {

  const { lgIsOwnerNameModified } = property.changeDetails;

  const { xOwner1First, xOwner1Last, xOwner2First, xOwner2Last } = getHONamesBaseDataClosureV2().call(this, property);
  // (lgIsOwnerNameModified ? getHONamesBaseDataClosureV2() : getHONamesOriginalDataClosureV2()).call(this, property);
  const {
    SiteNumber,
    SiteStreet,
    SiteCity,
    SiteState,
    SiteZip,
    MailAddress,
    MailCity,
    MailState,
    MailZip,
  } = property.mailingDetails || {};

  const { blocked } = property.changeDetails || {};
  let hoName = renderLogicUtil.getNoteGreetingTextV4(
    property.changeDetails,
    "and"
  );

  const handleFinishThisLater = () => {
    // rest.explicitEnableNewMailProInitFn(false);
    // rest.disableNewMailingForCurrentSessionFn(true);
    // rest.setNewMailProInitDialogModeFn(null);
    rest.onCloseClick();
  };

  return (
    <MuiDialogTitle disableTypography className={classes.headerMain}>
      <div className={classes.logoHeader}>
        <div className={classes.imageLogo}>
          <img
            src={LGWhiteLogo} //"https://listgen.com/images/listgen-text-advanced-v1.png"
            style={{ width: "100%" }}
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <p>AUTOMATED PERSONAL NOTE</p>
          <p>HAPPY ANNIVERSARY!</p>
        </div>
        {/* <div>
          <p>{listingAgentFullName}</p>
          <p>{listingOfficeName}</p>
        </div> */}
        <div className={classes.finishButton}>
          <MailProButton
            key={"anniversary_automator_finish_later"}
            variant="outlined"
            label={anniversarySkippableMailProIdsCount > 0 ? "I'LL FINISH THIS LATER" : "I'M ALL DONE — CLOSE"}
            customStyle={{ fontSize: "11px" }}
            onClick={handleFinishThisLater}
          />
        </div>
      </div>
      <div className={classes.headerExtraDetails}>
        {/* <div className={classes.finishLaterDetails}>
          <div></div>
          <div className={classes.finishButton}>
            <MailProButton
              key={"anniversary_automator_finish_later"}
              variant="outlined"
              label={"I'LL FINISH THIS LATER"}
              customStyle={{ fontSize: "11px" }}
              onClick={() => {}}
            />
          </div>
        </div> */}
        <div className={classes.mailingDetails}>
          {/* <div className={classes.mailingDetailsInner}> */}
          <div
            className={classNames(
              classes.nameContainer,
              classes.headerButtonandExtraFirst,
              classes.centerCenterAlign,
            )}
          >
            <div className={classNames(classes.addressHeading, classes.verticalLineBorder)}>
              <p>{lgIsOwnerNameModified ? 'Personalized' : 'Original'}</p>
              <p>HO Names</p>
            </div>
            <div>
              <p>
                {xOwner1First}&nbsp;{xOwner1Last}
              </p>
              {(xOwner2First) && (
                <p>
                  {xOwner2First}&nbsp;{xOwner2Last}
                </p>
              )}
            </div>
          </div>
          <div className={classNames(classes.addressContainer)}>
            <div className={classNames(classes.addressHeading, classes.verticalLineBorder)}>
              <p>Site</p>
              <p>Address</p>
            </div>
            <div className={classes.addressDetail}>
              <p>
                {propertyUtil.emptyValueCheck(SiteNumber).toUpperCase()}{" "}
                {propertyUtil.emptyValueCheck(SiteStreet).toUpperCase()}
              </p>
              <p>
                {propertyUtil.emptyValueCheck(SiteCity).toUpperCase()}{" "}
                {propertyUtil.emptyValueCheck(SiteState).toUpperCase()}{" "}
                {propertyUtil.emptyValueCheck(SiteZip).toUpperCase()}
              </p>
            </div>
          </div>
          <div
            className={classNames(
              classes.addressContainer,
              classes.headerButtonandExtraThird
            )}
            style={{ marginLeft: "32px" }}
          >
            <div className={classNames(classes.addressHeading, classes.verticalLineBorder)}>
              <p>Mailing</p>
              <p>Address</p>
            </div>
            <div className={classes.addressDetail}>
              <p>{propertyUtil.emptyValueCheck(MailAddress).toUpperCase()}</p>
              <p>
                {propertyUtil.emptyValueCheck(MailCity).toUpperCase()}{" "}
                {propertyUtil.emptyValueCheck(MailState).toUpperCase()}{" "}
                {propertyUtil.emptyValueCheck(MailZip).toUpperCase()}
              </p>
            </div>
          </div>
          {/* </div> */}
        </div>
        <div className={classes.headerButtonContainer}>
          <div
            className={classNames(
              classes.headerButtonandExtraFirst,
              classes.centerCenterAlign
            )}
            style={{justifyContent: 'flex-end'}}
          >
            <EditHomeownerDialogV2
              mailingDetails={property.mailingDetails}
              areaID={property.areaID}
              agentID={property.agentID}
              mailerProID={property.mailerProID}
              mailingStatus={property.mailingStatus}
              changeDetails={property.changeDetails}
              propertyData={propertyData}
              propertyDetails={property.propertyDetails}
              blocked={blocked}
              propertySequenceNumber={propertySequenceNumber}
              property={property}
              isMailProLocked={isMailProLocked}
            >
              <MailProButton
                key={"anniversary_automator_personalise_hos"}
                variant="outlined"
                label={"Personlize Homeowner's Name"}
                customStyle={{ fontSize: "11px" }}
                onClick={() => { }}
              />
            </EditHomeownerDialogV2>
          </div>
          <div className={classes.centerCenterAlign}>
            {/* <MailProButton
              key={"anniversary_automator_tag_ho"}
              variant="outlined"
              label={"Tag this Homeowner"}
              customStyle={{ fontSize: "10px" }}
              onClick={() => {}}
            /> */}
            <EditTagsDialog
              agentID={property.agentID}
              areaID={property.areaID}
              mailerProID={property.mailerProID}
              mailingStatus={property.mailingStatus}
              changeDetails={property.changeDetails}
              propertyDetails={property.propertyDetails}
              propertyData={propertyData}
              agentData={agentData}
              propertySequenceNumber={propertySequenceNumber}
              property={property}
              isMailProLocked={isMailProLocked}
            >
              <MailProButton
                key={"anniversary_automator_tag_ho"}
                variant="outlined"
                label={"Tag this Homeowner"}
                customStyle={{ fontSize: "11px", paddingLeft: '32px', paddingRight: '32px', fontWeight: 500 }}
                onClick={() => { }}
              />
            </EditTagsDialog>
          </div>
          <div
            className={classNames(
              classes.headerButtonandExtraThird,
              classes.headerButtonandExtraDisplay
            )}
            style={{justifyContent: 'flex-start'}}
          >
            {/* <MailProButton
              key={"anniversary_automator_block_mailing"}
              variant="outlined"
              label={"Block Mailings to this Homeowner"}
              customStyle={{ fontSize: "10px" }}
              onClick={() => {}}
            /> */}
            <BlockPropertyDialog
              blocked={blocked}
              areaID={property.areaID}
              agentID={property.agentID}
              mailerProID={property.mailerProID}
              mailingStatus={property.mailingStatus}
              changeDetails={property.changeDetails}
              propertyData={propertyData}
              propertyDetails={property.propertyDetails}
              agentData={agentData}
              mailingDetails={property.mailingDetails}
              propertySequenceNumber={propertySequenceNumber}
              property={property}
              isMailProLocked={isMailProLocked}
            >
              <MailProButton
                key={"anniversary_automator_block_mailing"}
                variant="outlined"
                label={"Block Mailings to this Homeowner"}
                customStyle={{ fontSize: "11px" }}
                onClick={() => { }}
              />
            </BlockPropertyDialog>
          </div>
        </div>
      </div>
    </MuiDialogTitle>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    explicitEnableNewMailProInitFn: (data) => dispatch(pmaActions.explicitEnableNewMailProInitFn(data)),
    disableNewMailingForCurrentSessionFn: (data) => dispatch(pmaActions.disableNewMailingForCurrentSessionFn(data)),
    setNewMailProInitDialogModeFn: (data) => dispatch(pmaActions.setNewMailProInitDialogModeFn(data))
  };
};

function mapStateToProps(state) {
  return {};
}

const AnniversaryAutomatorDialogHeaderEnhanced = compose(
  withSnackbar,
  connect(mapStateToProps, mapDispatchToProps)
)(AnniversaryAutomatorDialogHeader);

export default AnniversaryAutomatorDialogHeaderEnhanced;
