import React, {
  Component,
  Fragment,
  useState,
  useEffect,
  useContext,
} from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Divider,
  Input,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles from "../styles";
import { Dashboard as DashboardLayout } from "layouts";
import { withSnackbar } from "notistack";
import { Portlet } from "components";
import { PortletContent } from "components";
import { PortletHeader } from "components";
import classNames from "classnames";
import { PortletFooter } from "components";
import { PortletLabel } from "components";
import AddMissions from "./component/AddMissions";
import { GetPmaAdminDataAPI } from "services/agent";
import moment from "moment";
import { GetStateStorage, IsValidSessionSwitch } from "common/storage";
import { showSuccessNotification } from "common/helpers";
import { showErrorNotification } from "common/helpers";
import CommonConfirmationDialog from "components/CommonConfirmationDialog";
import { Store } from "Store";
import { UPDATE_PMA_MISSIONS_COUNT } from "store/types";
import Base from "common/constants";
import DocumentTitle from "react-document-title";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Skeleton } from "@material-ui/lab";
import { AddUpdatePmaAdmin } from "services/agent";
import palette from "theme/palette";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const MissionContent = (props) => {
  const {
    classes,
    isLoading,
    isPrinted,
    isAdminAccess,
    iKey,
    item,
    handleFormOpen,
    askForConfirmation,
    quickUpdateAConstant,
  } = props;

  const params = useParams();
  const { mlsAreaID } = params;
  // yuo can find all params from here
  console.log("mlsAreaID", mlsAreaID);
  const convertTimeToUSATime = (inputTime) => {
    return moment(new Date(inputTime)).format("MMM DD, YYYY");
  };

  return (
    <div
      className={classNames(
        classes.testimonialTextboxDiv,
        classes.testimonialTextboxDivBorder,
        classes.displyFlex,
        item.isCurrentlyActive
          ? classNames(classes.highlightBorder, classes.lightBlueBG)
          : ""
      )}
    >
      {/* (isPrinted ? classes.lightBlueBG : '') */}
      <div className={classNames(classes.textboxLeftTM, classes.columnFlex)}>
        <div
          className={classNames(
            classes.displyFlex,
            classes.spaceBWJustify,
            classes.rowFlex
          )}
        >
          {!isLoading ? (
            <div>
              <Typography variant="subtitle2">
                {/* {item.isCurrentlyActive ? 'Highlighted ' : ''} */}
                Mission Statement # {iKey + 1}
              </Typography>
              {item.isCurrentlyActive ? (
                <div style={{ marginTop: "8px" }}>
                  <Typography
                    variant="subtitle2"
                    className={classes.paletteRed}
                    component="p"
                  >
                    This is the Mission Statement that will be printed on your
                    PMA.
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <Skeleton animation="wave" height={20} width="10%" />
          )}
          {!isLoading ? (
            <Typography variant="body1" component="p">
              Created:{" "}
              {item.createdDate
                ? convertTimeToUSATime(item.createdDate)
                : "---"}
            </Typography>
          ) : (
            <Skeleton animation="wave" height={20} width="10%" />
          )}

          {!isLoading && item.isCurrentlyActive && (
            <Button
              href={`/pma/${mlsAreaID}`}
              variant="contained"
              style={{
                backgroundColor: palette.success.dark,
                color: "white",
                marginBottom: "12px",
              }}

              //size="small"
            >
              Back To PMA
            </Button>
          )}

          {!isLoading ? (
            <Typography
              variant="body1"
              className={isPrinted ? classes.red900 : ""}
            >
              Last date printed/used:{" "}
              {item.lastUsedDate &&
              item.lastUsedDate !== null &&
              item.isAlreadyUsed
                ? convertTimeToUSATime(item.lastUsedDate)
                : "---"}
            </Typography>
          ) : (
            <Skeleton animation="wave" height={20} width="10%" />
          )}
        </div>
        {!isLoading ? (
          <div
            className={classNames(
              classes.displyFlex,
              classes.centerAlign,
              classes.spaceBWJustify
            )}
          >
            <Checkbox
              color="primary"
              onClick={(event) =>
                quickUpdateAConstant(item.missionStatementID, "set")
              }
              disabled={isLoading || item.isCurrentlyActive}
              checked={item.isCurrentlyActive}
            />
            <div
              className={classNames(
                classes.addedMissionsDiv,
                isPrinted ? classNames(classes.blueGrayBorder) : "",
                classes.wordBreak
              )}
            >
              <Typography
                variant="h6"
                style={{
                  color: palette.primary.currentLightBlue,
                  marginBottom: "12px",
                }}
              >
                Mission Header:{" "}
                {item && item.missionStatementHeader
                  ? item.missionStatementHeader
                  : "-"}
              </Typography>
              <Typography variant="body1">
                {item && item.missionStatementContent}
              </Typography>
            </div>
          </div>
        ) : (
          <Skeleton animation="wave" height={100} width="100%" />
        )}
        <div className={classNames(classes.displyFlex, classes.flexEndJustify)}>
          {!isLoading ? (
            <div>
              {isAdminAccess ? (
                item.missionStatementVerificationStatus === "verified" ? (
                  <div
                    className={classNames(
                      classes.green900,
                      classes.bold700Font,
                      classes.uppercase,
                      classes.px14
                    )}
                  >
                    Verified
                  </div>
                ) : (
                  <div
                    className={classNames(
                      classes.red900,
                      classes.bold700Font,
                      classes.uppercase,
                      classes.px14
                    )}
                  >
                    Not Verified
                  </div>
                )
              ) : (
                ""
              )}
            </div>
          ) : (
            <Skeleton animation="wave" height={10} width="10%" />
          )}
          <div
            className={classNames(
              classes.width50,
              classes.displyFlex,
              classes.flexEndJustify
            )}
          >
            {!isLoading ? (
              <Typography
                variant="subtitle2"
                className={classNames(
                  classes.hoRectangularBox,
                  classes.lightBlueBG
                )}
              >
                {/* Agent First / Team name: */}
                {`${item.namePreferred}`}
              </Typography>
            ) : (
              <Skeleton animation="wave" height={10} width="40%" />
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames(
          classes.textboxRightTM,
          classes.displyFlex,
          classes.columnFlex,
          classes.centerAlign,
          classes.centerJustify
        )}
      >
        {!isLoading ? (
          <Fragment>
            <Button
              color="primary"
              onClick={(event) => handleFormOpen("update", item)}
            >
              {isAdminAccess ? "Verify" : "Edit"}
            </Button>
            {!item.isCurrentlyActive ? (
              <Button
                color="primary"
                className={classNames(classes.red900, classes.bold500Font)}
                onClick={(event) => askForConfirmation(item.missionStatementID)}
              >
                Delete
              </Button>
            ) : (
              <Tooltip
                title={
                  "Highlighted Mission Statement cannot be deleted. If you want to delete than Highlight an another Mission Statement."
                }
                arrow
              >
                <span>
                  <Button
                    color="primary"
                    className={classNames(classes.red900, classes.bold500Font)}
                    disabled={true}
                  >
                    Delete
                  </Button>
                </span>
              </Tooltip>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <Skeleton animation="wave" height={50} width="80%" />
            <Skeleton animation="wave" height={50} width="80%" />
          </Fragment>
        )}
      </div>
    </div>
  );
};

const AgentMissionStatements = (props) => {
  console.log("mission statement props", props);
  const { classes } = props;
  const { dispatch } = useContext(Store);
  //state storage
  const isAValidSessionSwitch = IsValidSessionSwitch();
  const { userData } = GetStateStorage();
  const [addedMissions, setAddedMissions] = React.useState([]);

  const [showAddDialog, setAddDialog] = React.useState(false);
  const [oldData, setOldData] = React.useState(null);
  const [mode, setMode] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFeatureLoading, setFeatureLoading] = useState(false);
  const [showConfirmationDialog, setConfirmationDialog] = React.useState(false);
  const [confirmationConstantID, setConfirmationConstantID] = React.useState(
    null
  );

  //check for admin access
  const isAdminAccess =
    isAValidSessionSwitch ||
    userData.listgenRoleID === Base.APP_DATA.APP_ADMIN_ROLE_ID;

  const todayDate = moment(new Date()).format("YYYY-MM-DD");

  //load all the missions
  useEffect(() => {
    window.scrollTo(0, 0);
    if (addedMissions.length === 0) {
      loadAllMissions();
    }
  }, []);

  //for the edit moe send old data also
  const handleFormOpen = (mode = "add", oldData = null) => {
    setAddDialog(true);
    setOldData(oldData);
    setMode(mode);
  };

  const handleFormClose = () => {
    setAddDialog(false);
    setOldData(null);
    setMode(null);
  };

  const sortMissions = (missionssData) => {
    return missionssData.sort((a, b) => {
      return b.isCurrentlyActive === a.isCurrentlyActive
        ? 0
        : b.isCurrentlyActive
        ? 1
        : -1;
    });
  };

  const loadAllMissions = () => {
    /**make loading true */
    setIsLoading(true);
    try {
      GetPmaAdminDataAPI("getMissionStatements", userData.listgenUserID)
        .then((data) => {
          if (data.length > 0) {
            //sort the data now
            setAddedMissions(sortMissions(data));
          } else {
            setAddedMissions([]);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setAddedMissions([]);
        });
    } catch (err) {
      setIsLoading(false);
      setAddedMissions([]);
    }
  };

  const pushDataToState = (mode, missionsData, missionStatementID) => {
    let addedXMissions = [...addedMissions];
    let missionsCount = 0;
    if (mode === "add") {
      //added new mission with is currently active as true
      let data = addedXMissions.map((item) => {
        item.isCurrentlyActive = false;
        return item;
      });
      data.unshift(missionsData);
      missionsCount = data.length;
      setAddedMissions(sortMissions(data));
    } else if (mode === "update") {
      let data = addedXMissions.map((item) => {
        if (item.missionStatementID === missionStatementID) {
          item = missionsData;
        }
        return item;
      });
      missionsCount = data ? data.length : 0;
      setAddedMissions(sortMissions(data ? data : []));
    } else if (mode === "delete") {
      let data = addedXMissions.filter(
        (item) => item.missionStatementID !== missionStatementID
      );
      missionsCount = data ? data.length : 0;
      setAddedMissions(data ? data : []);
    } else if (mode === "set") {
      let data = addedXMissions.map((item) => {
        if (item.missionStatementID === missionStatementID) {
          item.isCurrentlyActive = true;
        } else {
          item.isCurrentlyActive = false;
        }
        return item;
      });
      missionsCount = data ? data.length : 0;
      setAddedMissions(sortMissions(data ? data : []));
    }
    //update the state
    dispatch({
      type: UPDATE_PMA_MISSIONS_COUNT,
      count: missionsCount,
    });
  };

  const quickUpdateAConstant = (missionStatementID, mode = "delete") => {
    /**get the charge data */
    /**make loading true */
    try {
      GetPmaAdminDataAPI(
        mode === "set" ? "setCurrentStatement" : "deleteStatement",
        userData.listgenUserID,
        { missionStatementID }
      )
        .then((data) => {
          showSuccessNotification(
            props,
            mode === "set"
              ? `Mission Statement successfully highlighted.`
              : `Mission Statement deleted.`
          );
          pushDataToState(
            mode === "set" ? "set" : "delete",
            {},
            missionStatementID
          );
        })
        .catch((err) => {
          showErrorNotification(props, "Unable to process request.");
        });
    } catch (err) {
      showErrorNotification(props, "Unable to process request.");
    }
  };

  const handleAddOrUpdateMission = (
    mode,
    newData,
    oldData = {},
    callbackFn = () => {}
  ) => {
    /**get the charge data */
    /**make loading true */
    try {
      //if hightlightMode is true then assign as true. check for isCurrentlyActive used correct data. in create api we are not getting this back so added this check
      newData["isCurrentlyActive"] =
        newData.isCurrentlyActive === undefined ||
        newData.isCurrentlyActive === null
          ? false
          : newData.isCurrentlyActive;
      //save the data now
      AddUpdatePmaAdmin(
        mode === "add" ? "addMission" : "updateMission",
        newData
      )
        .then((data) => {
          showSuccessNotification(
            props,
            mode === "add"
              ? `Mission Statement successfully added.`
              : `Mission Statement successfully updated.`
          );
          callbackFn(true);
          //agent charged so close the dialog
          //call local state
          pushDataToState(mode, data, oldData && oldData.missionStatementID);
          //close the dialog if required.
        })
        .catch((err) => {
          showErrorNotification(
            props,
            "Something went wrong while processing your request."
          );
          callbackFn(false);
        });
    } catch (err) {
      showErrorNotification(props, "Unable to process request.");
    }
  };

  const askForConfirmation = (lgMissionID) => {
    setConfirmationDialog(true);
    setConfirmationConstantID(lgMissionID);
  };

  const hideConfirmationDialog = () => {
    setConfirmationConstantID(null);
    setConfirmationDialog(false);
  };

  const isBeforeDataCheck = (usedDate, isAlreadyUsed) => {
    if (usedDate && usedDate !== null && isAlreadyUsed) {
      return moment(new Date(usedDate)).isBefore(todayDate);
    }
    return false;
  };

  return (
    <Fragment>
      {showAddDialog ? (
        <AddMissions
          mode={mode}
          oldData={oldData}
          onCloseFn={handleFormClose}
          completeData={addedMissions}
          listgenUserID={userData.listgenUserID}
          //localUpdateFn={pushDataToState}
          handleAddOrUpdateMission={handleAddOrUpdateMission}
          isAdminAccess={isAdminAccess}
          userData={userData}
        />
      ) : (
        ""
      )}
      {showConfirmationDialog ? (
        <CommonConfirmationDialog
          title={`Are you sure you want to delete this?`}
          subtitle={`Once you delete a Mission Statement it will be deleted forever. It won't be recoverable.`}
          closeFn={hideConfirmationDialog}
          methodToCallOnConfirmation={quickUpdateAConstant}
          confirmationID={confirmationConstantID}
        />
      ) : (
        ""
      )}
      <DocumentTitle title="Agent Mission Statements">
        <DashboardLayout title="Agent Mission Statements">
          <div className={classNames(classes.root, classes.rootMinHeight)}>
            <Portlet className={classes.content}>
              <PortletHeader className={classes.stickyHead}>
                <PortletLabel
                  title=""
                  //Existing Missions Statements
                  className={classes.primaryColor}
                />
                {addedMissions.length > 0 ? (
                  <div>
                    <Typography
                      variant="subtitle2"
                      className={classes.paletteRed}
                      component="p"
                    >
                      Click on the Mission Statement Checkbox that you would
                      like to print on your PMA.
                    </Typography>
                  </div>
                ) : (
                  ""
                )}
                <Button
                  onClick={(event) => handleFormOpen("add", null)}
                  variant="contained"
                  color="primary"
                  //size="small"
                >
                  Create Your Own Mission Statement
                </Button>
              </PortletHeader>
              <PortletContent noPadding>
                {isLoading ? (
                  // <div className={classNames(classes.progressWrapper, classes.noDataDiv)}>
                  //   <CircularProgress />
                  // </div>
                  <div>
                    {[1, 2, 3, 4].map((item, key) => (
                      <MissionContent
                        classes={classes}
                        isLoading={isLoading}
                        isPrinted={false}
                        isAdminAccess={false}
                        iKey={null}
                        item={{}}
                        handleFormOpen={() => {}}
                        askFroConfirmation={() => {}}
                        highlightMissionsFn={() => {}}
                        key={key + item.missionStatementID}
                      />
                    ))}
                  </div>
                ) : addedMissions.length ? (
                  addedMissions.map((item, key) => {
                    let isPrinted = isBeforeDataCheck(
                      item.lastUsedDate,
                      item.isAlreadyUsed
                    );
                    return (
                      <MissionContent
                        classes={classes}
                        isLoading={isLoading}
                        isPrinted={isPrinted}
                        isAdminAccess={isAdminAccess}
                        iKey={key}
                        item={item}
                        handleFormOpen={handleFormOpen}
                        askForConfirmation={askForConfirmation}
                        quickUpdateAConstant={quickUpdateAConstant}
                        key={item.missionStatementID}
                      />
                    );
                  })
                ) : (
                  <div
                    className={classNames(
                      classes.width100,
                      classes.displyFlex,
                      classes.centerJustify,
                      classes.centerAlign,
                      classes.noDataDiv
                    )}
                  >
                    <Typography variant="subtitle2">
                      Looks like no Mission Statement has been added. Please add
                      a Mission Statement by clicking on "
                      <span className={classes.bold500Font}>
                        Create a New Mission Statement
                      </span>
                      ".
                    </Typography>
                  </div>
                )}
              </PortletContent>
            </Portlet>
          </div>
        </DashboardLayout>
      </DocumentTitle>
    </Fragment>
  );
};

AgentMissionStatements.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default compose(
  withSnackbar,
  withStyles(styles)
)(AgentMissionStatements);
