import React, { Component } from "react";
import QVFourPresentedByFooter from "views/Pma/PmaEditor/ThirdPageComponents/QVFourPresentedByFooter";
import PS from "views/Pma/PmaEditor/CommonEditor/PS";
import QVFourPhotoSection from "../../../Quarterly/QVFour/QVFourSecondPage/QVFourPhotoSection";
import AgentLogoHeader from "views/Pma/PmaEditor/ThirdPageComponents/AgentLogoHeader";
import QVFourPS from "../../../Quarterly/QVFour/QVFourSecondPage/QVFourPS";

class MQTwoSecondRight extends Component {
  render() {
    const {
      agentData,
      hoData,
      qArea,
      currentArea,
      printVersion,
      version,
      mlsAreas,
      MQCityDetails,
      quarterlyAreaTable,
      quarterlyCityTable,
    } = this.props;
    console.log("ho data", hoData);
    let isBuellton =
      currentArea.mlsAreaID === "SBA-40F" ||
      currentArea.mlsAreaID === "SBA-40G";
    return (
      <div className="mq2-second-right">
        <QVFourPresentedByFooter
          agentData={agentData}
          currentArea={currentArea}
          printVersion={printVersion}
        />
        <AgentLogoHeader agentData={agentData} printVersion={printVersion} />
        {hoData &&
          hoData.changeDetails &&
          Object.keys(hoData.changeDetails).length > 1 && (
            <QVFourPS
              agentData={agentData}
              hoData={hoData}
              currentArea={currentArea}
              mlsAreas={mlsAreas}
              version={version}
              printVersion={printVersion}
              mailingNum="mq2"
            />
          )}
        <QVFourPhotoSection
          currentArea={currentArea}
          agentData={agentData}
          printVersion={printVersion}
          version={version}
          hoData={hoData}
          quarterlyCityTable={quarterlyCityTable}
          currentSoldListings={
            isBuellton
              ? quarterlyCityTable.currentSoldListings
              : quarterlyAreaTable.currentSoldListings
          }
        />
      </div>
    );
  }
}

export default MQTwoSecondRight;
