import React, { Component } from "react";

import QVFourPhotoSection from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPhotoSection";
import QVFourPhotos from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPhotos";
import QVFourPS from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPS";
import AgentLogoHeader from "views/Pma/PmaEditor/ThirdPageComponents/AgentLogoHeader";
import QVFourPresentedByFooter from "views/Pma/PmaEditor/ThirdPageComponents/QVFourPresentedByFooter";

class QVTwoSecondRight extends Component {
  render() {
    const {
      agentData,
      hoData,
      qArea,
      currentArea,
      printVersion,
      version,
      mlsAreas,
      MQCityDetails,
      aprilData,
      quarterlyAreaTable,
      demoAccountAreaName,
      demoAccountHOName,
      recentActivityPhotoList,
      photoCheckboxModalOpen,
    } = this.props;
    const { lastPMAPrintDate } = agentData;
    console.log("qv2 second right props", this.props);
    return (
      <div className="qv2-second-right">
        {!photoCheckboxModalOpen && (
          <AgentLogoHeader
            agentData={agentData}
            currentArea={currentArea}
          />
        )}
        {!photoCheckboxModalOpen && (
          <QVFourPS
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            version={version}
            printVersion={printVersion}
            mailingNum="qv4"
            demoAccountHOName={demoAccountHOName}
            // lastPMAPrintDate={lastPMAPrintDate}
            // lastPMAPrintDate="2021-08-30"
          />
        )}

        {/*<QVFourPhotos
          currentArea={currentArea}
          agentData={agentData}
          printVersion={printVersion}
          version={version}
          MQCityDetails={MQCityDetails}
          mlsAreaID={currentArea.mlsAreaID}
          quarterlyAreaTable={quarterlyAreaTable}
          hoData={hoData}
          demoAccountAreaName={demoAccountAreaName}

          currentSoldListings={recentActivityPhotoList}
          recentActivityPhotoList={recentActivityPhotoList}
        />*/}
        <QVFourPhotoSection
          currentArea={currentArea}
          agentData={agentData}
          printVersion={printVersion}
          version={version}
          MQCityDetails={MQCityDetails}
          mlsAreaID={currentArea.mlsAreaID}
          quarterlyAreaTable={quarterlyAreaTable}
          hoData={hoData}
          demoAccountAreaName={demoAccountAreaName}
          currentSoldListings={
            aprilData.pmaQuarterlyAreaDTO.currentSoldListings
          }
        />
        {!photoCheckboxModalOpen && (
          <QVFourPresentedByFooter
            agentData={agentData}
            currentArea={currentArea}
            currentSoldListings={
              aprilData.pmaQuarterlyAreaDTO.currentSoldListings
            }
            printVersion={printVersion}
          />
        )}
      </div>
    );
  }
}

export default QVTwoSecondRight;
