import React, { Component } from "react";
import AgentLogoHeader from "views/Pma/PmaEditor/ThirdPageComponents/AgentLogoHeader";
import QVFourPS from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPS";
import QRCode from "views/Pma/PmaEditor/ThirdPageComponents/QRCode";
import MissionStatementBlock from "views/Pma/PmaEditor/ThirdPageComponents/MissionStatementBlock";
import QVFourPresentedByFooter from "views/Pma/PmaEditor/ThirdPageComponents/QVFourPresentedByFooter";

class ThirdPageComponents extends Component {
  render() {
    const {
      currentArea,
      agentData,
      printVersion,
      version,
      hoData,
      mlsAreas,
      aprilData,
      demoAccountHOName,
      propertyPhotoDetailsDTO,
      oldPhotoDetails,
      photoTableData,
      quarterlyAreaTable,
      quarterlyCityTable,
      MQCityDetails,
      isDemo,
      missionStatement,
    } = this.props;
    const { listgenUserID } = agentData;
    const { mlsAreaID } = currentArea;
    const { currentSoldListings } = quarterlyAreaTable;

    return (
      <div
        className={`${
          version === "year-end-2022" ? "ye2" : "qv4"
        }-second-right`}
      >
        <AgentLogoHeader
          agentData={agentData}
          currentArea={currentArea}
          printVersion={printVersion}
        />
        {hoData &&
          hoData.changeDetails &&
          Object.keys(hoData.changeDetails).length > 1 && (
            <QVFourPS
              agentData={agentData}
              hoData={hoData}
              currentArea={currentArea}
              mlsAreas={mlsAreas}
              version={version}
              printVersion={printVersion}
              mailingNum="qv4"
              demoAccountHOName={demoAccountHOName}
              isDemo={isDemo}
            />
          )}
        <QRCode
          hoData={hoData}
          agentData={agentData}
          smallerQR={true}
          printVersion={printVersion}
        />
        <MissionStatementBlock
          printVersion={printVersion}
          missionStatement={missionStatement}
          agentData={agentData}
          currentArea={currentArea}
        />
        <QVFourPresentedByFooter
          agentData={agentData}
          currentArea={currentArea}
          currentSoldListings={currentSoldListings}
          printVersion={printVersion}
        />
      </div>
    );
  }
}

export default ThirdPageComponents;
