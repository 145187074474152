import React, { Component, Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link, withRouter } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import {
  Portlet,
  PortletContent,
  PortletFooter,
  PortletHeader,
  PortletLabel,
} from 'components';
import { Grid, Typography, Divider } from '@material-ui/core';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import _ from 'lodash';
import { withStyles, CircularProgress } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import styles from './styles';
import { WebsiteLayout } from 'layouts';
import { Step1Form } from './component';
import {
  MainSchema,
  TeamMemberSchema,
  TeamMembersRequiredSchema,
  TeamMembersNotRequiredSchema,
} from './schema';
import { Step2Form } from './component';
import { Step3Form } from './component';
import { Step4Form } from './component';
import { Step5Form } from './component';
import querystring from 'querystring';

/**Client code, Don't use other other spinner. material already have spinner */
import { connect } from 'react-redux';

import { authActions } from '../../_store/_actions';
import { TeamDetailsForm } from './component';
import { RegisterAgentValidate } from 'services/agent';
import { OnLoadRegisterAgentData } from 'services/agent';
import { RegisterAgentValidateEmail } from 'services/agent';
import { showSuccessNotification } from 'common/helpers';
import { showErrorNotification } from 'common/helpers';
import { RegisterAgentOnSubmitAPI } from 'services/agent';
import { RegisterAgentUploadPhotoAPI } from 'services/agent';
import { GetSemiAuthStateStorage, ResetAllLocalStorages } from 'common/storage';
import { GetAllDesignationsData } from 'services/others';
import { CardLayoutComponent } from 'components';
import { AddAgentCCInfo } from 'services/agent';
import DocumentTitle from 'react-document-title';
import { UpdateMailproAfterRegistrations } from 'services/agent';

function getSteps() {
  return [
    'Personal Info',
    'Mailing Address',
    'Agent Marketing',
    'Team Info',
    'Terms and Conditions',
    'Billing Info',
  ];
}

class RegisterAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      values: {
        firstName: '',
        lastName: '',
        agentLicenseNumber: '', //
        ownedMLSID: [],
        brokerLicenseNumber: '', //
        emailAddress: '',
        mobilePhone: '',
        officePhone: '',
        agentDesignation: 'Realtor',
        brokerageName: '',
        //assistants: [],
        password: '',
        confirmPassword: '',
        mailingAddressLine1: '',
        mailingAddressLine2: '',
        city: '',
        state: '',
        zipcode: '',
        website: '',
        tagline: '',

        officeAddressLine1: '',
        officeAddressLine2: '',
        officeCity: '',
        officeState: '',
        officeZipcode: '',

        agentPhoto: '',
        agentLogo: '',
        agentLogo2: '',
        agentSignature: '',
        agentBrokerageLogoURL: '',
        billingAddresSameAsMailingAddress: false,
        billingFirstName: '',
        billingLastName: '',
        billingAddressLine1: '',
        billingAddressLine2: '',
        billingCity: '',
        billingState: '',
        billingZipcode: '',
        isMemberOfTeam: false,
        teamName: '',
        acceptedTermsAndConditions: true,
        /**credit card data */
        autoPayAccepted: true,
        nameOnCard: '',
        cardNumber: '',
        expiryMonth: '',
        expiryYear: '',
        cvc: '',

        teamMembers: [
          {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            agentLicenseNumber: '',
            mailingAddress1: '',
            mailingAddress2: '',
            city: '',
            state: '',
            zipcode: '',
            agentSignature: '',
            agentDesignation: '',
          },
          {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            agentLicenseNumber: '',
            mailingAddress1: '',
            mailingAddress2: '',
            city: '',
            state: '',
            zipcode: '',
            agentSignature: '',
            agentDesignation: '',
          },
          {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            agentLicenseNumber: '',
            mailingAddress1: '',
            mailingAddress2: '',
            city: '',
            state: '',
            zipcode: '',
            agentSignature: '',
            agentDesignation: '',
          },
          {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            agentLicenseNumber: '',
            mailingAddress1: '',
            mailingAddress2: '',
            city: '',
            state: '',
            zipcode: '',
            agentSignature: '',
            agentDesignation: '',
          },
        ]
      },
      errors: {
        firstName: null,
        lastName: null,
        agentDesignation: null,
        brokerageName: null,
        agentLicenseNumber: null,
        brokerLicenseNumber: null,
        emailAddress: null,
        mobilePhone: null,
        officePhone: null,
        website: null,
        tagline: null,
        assistants: [],
        password: null,
        confirmPassword: null,
        mailingAddressLine1: null,
        mailingAddressLine2: null,
        city: null,
        state: null,
        zipcode: null,

        officeAddressLine1: null,
        officeAddressLine2: null,
        officeCity: null,
        officeState: null,
        officeZipcode: null,

        billingFirstName: null,
        billingLastName: null,
        billingAddressLine1: null,
        billingAddressLine2: null,
        billingCity: null,
        billingState: null,
        billingZipcode: null,

        autoPayAccepted: false,
        nameOnCard: '',
        cardNumber: '',
        expiryMonth: '',
        expiryYear: '',
        cvc: '',

        teamMembers: [
          {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            agentLicenseNumber: '',
            mailingAddress1: '',
            mailingAddress2: '',
            city: '',
            state: '',
            zipcode: '',
            agentSignature: '',
            agentDesignation: '',
          },
          {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            agentLicenseNumber: '',
            mailingAddress1: '',
            mailingAddress2: '',
            city: '',
            state: '',
            zipcode: '',
            agentSignature: '',
            agentDesignation: '',
          },
          {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            agentLicenseNumber: '',
            mailingAddress1: '',
            mailingAddress2: '',
            city: '',
            state: '',
            zipcode: '',
            agentSignature: '',
            agentDesignation: '',
          },
          {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            agentLicenseNumber: '',
            mailingAddress1: '',
            mailingAddress2: '',
            city: '',
            state: '',
            zipcode: '',
            agentSignature: '',
            agentDesignation: '',
          },
        ],
      },
      touched: {
        firstName: false,
        lastName: false,
        agentDesignation: false,
        agentLicenseNumber: false,
        brokerageName: false,
        brokerLicenseNumber: false,
        emailAddress: false,
        mobilePhone: false,
        officePhone: false,
        website: false,
        tagline: false,
        assistants: [],
        password: false,
        confirmPassword: false,
        mailingAddressLine1: false,
        mailingAddressLine2: false,
        city: false,
        state: false,
        zipcode: false,

        officeAddressLine1: false,
        officeAddressLine2: false,
        officeCity: false,
        officeState: false,
        officeZipcode: false,

        billingFirstName: false,
        billingLastName: false,
        billingAddressLine1: false,
        billingAddressLine2: false,
        billingCity: false,
        billingState: false,
        billingZipcode: false,

        autoPayAccepted: false,
        nameOnCard: '',
        cardNumber: '',
        expiryMonth: '',
        expiryYear: '',
        cvc: '',

        teamMembers: [
          {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            agentLicenseNumber: '',
            mailingAddress1: '',
            mailingAddress2: '',
            city: '',
            state: '',
            zipcode: '',
            agentSignature: '',
            agentDesignation: '',
          },
          {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            agentLicenseNumber: '',
            mailingAddress1: '',
            mailingAddress2: '',
            city: '',
            state: '',
            zipcode: '',
            agentSignature: '',
            agentDesignation: '',
          },
          {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            agentLicenseNumber: '',
            mailingAddress1: '',
            mailingAddress2: '',
            city: '',
            state: '',
            zipcode: '',
            agentSignature: '',
            agentDesignation: '',
          },
          {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            agentLicenseNumber: '',
            mailingAddress1: '',
            mailingAddress2: '',
            city: '',
            state: '',
            zipcode: '',
            agentSignature: '',
            agentDesignation: '',
          },
        ],
      },
      stepButtonDisable: [true, true, false, true, true],
      isValid: false,
      isTeamMemberValid: false,
      numOfTeamMembers: [0, 1],
      addTeamMemberError: false,
      isLoading: false,
      registerToken: null,
      registerEmailAddress: null,
      isTokenExpired: false,
      registrationCompleted: false,
      acceptedTermsAndConditions: false,
      registerAgentData: {},
      stepValidationKeys: [
        [
          'firstName',
          'lastName',
          'agentLicenseNumber',
          'brokerLicenseNumber',
          'emailAddress',
          'mobilePhone',
          //'officePhone',
          'password',
          'confirmPassword',
          'agentDesignation',
        ],
        ['mailingAddressLine1', 'city', 'state', 'zipcode'],
        [
          //'website',
          //'tagline',
          //'officeAddressLine1',
          //'officeAddressLine2',
          // 'officeCity',//if we make office details mandatory again then mandatory these keys officeAddressLine1,officeCity,officeState,officeZipcode and also from state constructor set third key to true again stepButtonDisable: [true, true, false, true, true],
          //'officeState',
          //'officeZipcode',
          //'agentPhoto',
          //'agentLogo',
          //'agentLogo2',
          //'agentSignature',
        ],
        ['teamName'],
        [],
        [
          'billingFirstName',
          'billingLastName',
          'billingAddressLine1',
          'billingCity',
          'billingState',
          'billingZipcode',
          'autoPayAccepted',
          'nameOnCard',
          'cardNumber',
          'expiryMonth',
          'expiryYear',
          'cvc',
        ],
      ],
      designationData: [],
      isTeamMemberTouched: false, //this flag useful for first time validation for team member.
    };
    /**listgen logo */
    this.listgenLogo = '/images/listgen-logo.png';
    this.listgenAltLogo = '/images/listgen-text-advanced-v1.png';
    /**bind methods */
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.enableStepButton = this.enableStepButton.bind(this);
    this.handleTeamFieldChange = this.handleTeamFieldChange.bind(this);
    this.validateRegisterToken = this.validateRegisterToken.bind(this);
    this.getRegisterAgentOnLoadData = this.getRegisterAgentOnLoadData.bind(
      this
    );
    this.validateRegisterEmail = this.validateRegisterEmail.bind(this);
    this.dataPacther = this.dataPacther.bind(this);
    this.setEmailToInvalid = this.setEmailToInvalid.bind(this);
    this.validateAgentLicenseNumber = this.validateAgentLicenseNumber.bind(
      this
    );
    this.validateBrokerageLicenseNumber = this.validateBrokerageLicenseNumber.bind(
      this
    );
    this.completeAgentRegistration = this.completeAgentRegistration.bind(this);
    this.copyValuesFromMailingToBilling = this.copyValuesFromMailingToBilling.bind(
      this
    );
    this.truncateTeamMember = this.truncateTeamMember.bind(this);
    this.acceptTermsAndConditions = this.acceptTermsAndConditions.bind(this);
    this.notPartOfATeam = this.notPartOfATeam.bind(this);
    this.addTeamMember = this.addTeamMember.bind(this);
    this.deleteTeamMember = this.deleteTeamMember.bind(this);
    this.isPartOfATeam = this.isPartOfATeam.bind(this);
    this.haveErrors = this.haveErrors.bind(this);
    this.setTeamMemberTouched = this.setTeamMemberTouched.bind(this);
    this.analyzeTeamMemberDetails = this.analyzeTeamMemberDetails.bind(this);
    /**define custom variables */
  }

  componentWillMount = () => {
    try {
      // let parsedParams = querystring.parse(
      //   this.props.location.search.substring(1)
      // );
      let parsedParams = GetSemiAuthStateStorage();
      this.setState({
        registerToken: parsedParams.registerToken,
        registerEmailAddress: parsedParams.emailAddress,
      });
    } catch (err) {
      /**set token expired to true and disable page */
      this.setState({
        registerToken: null,
        registerEmailAddress: null,
        isTokenExpired: true,
      });
    }
  };

  componentDidMount = () => {
    if (this.state.registerToken) {
      /**call validate only if token is present */
      this.validateRegisterToken();
    }
  };

  startLoading = () => {
    this.setState({
      isLoading: true,
    });
  };

  stopLoading = () => {
    this.setState({
      isLoading: false,
    });
  };

  haveErrors = data => {
    if (data || Array.isArray(data)) {
      return true;
    } else {
      return false;
    }
  };

  dataPacther = (reverseMode = false, stateData, serverData) => {
    //password, confirmPassword, agentLicenseNumber, brokerLicenseNumber team members removed
    let stateKeys = [
      'firstName',
      'lastName',
      'agentLicenseNumber',
      'brokerLicenseNumber',
      'brokerageName',
      'emailAddress',
      'mobilePhone',
      'officePhone',
      'mailingAddressLine1',
      'mailingAddressLine2',
      'city',
      'state',
      'zipcode',
      'website',
      'tagline',
      'ownedMLSID',

      'officeAddressLine1',
      'officeAddressLine2',
      'officeCity',
      'officeState',
      'officeZipcode',

      'agentPhoto',
      'agentLogo',
      'agentLogo2',
      'agentSignature',
      'agentBrokerageLogoURL',
      'billingAddresSameAsMailingAddress',
      'billingFirstName',
      'billingLastName',
      'billingAddressLine1',
      'billingAddressLine2',
      'billingCity',
      'billingState',
      'billingZipcode',
      'isMemberOfTeam',
      'teamName'
    ];
    let serverKeys = [
      'listgenUserFirstName',
      'listgenUserLastName',
      'agentLicenseNumber',
      'brokerLicenseNumber',
      'brokerageName',
      'emailAddress',
      'mobilePhoneNo',
      'officePhoneNo',
      'mailingAddressLine1',
      'mailingAddressLine2',
      'mailingCity',
      'mailingState',
      'mailingZipCode',
      'websiteURL',
      'tagLine',
      'ownedMLSID',

      'officeAddressLine1',
      'officeAddressLine2',
      'officeCity',
      'officeState',
      'officeZipcode',

      'photoURL',
      'logoURL',
      'logoURL2',
      'signatureURL',
      'brokerageLogoURL',
      'isBillingAddressSame',
      'billingFirstName',
      'billingLastName',
      'billingAddressLine1',
      'billingAddressLine2',
      'billingCity',
      'billingState',
      'billingZipCode',
      'isMemberOfTeam',
      'teamName'
    ];
    let from = [];
    let fromData = [];
    let to = [];
    let toData = [];
    if (reverseMode) {
      from = stateKeys;
      to = serverKeys;
      /**assign data also */
      fromData = _.cloneDeep(stateData);
      toData = _.cloneDeep(serverData);
      /**now patch the data */
      let returnData = {};
      returnData = _.cloneDeep(serverData);
      returnData = _.omit(returnData, from);
      for (let key in from) {
        //if (fromData[from[key]])
        returnData[to[key]] =
          fromData.hasOwnProperty(from[key]) && fromData[from[key]] !== null
            ? fromData[from[key]]
            : '';
        //check for website. from app to server
        if (from[key] === 'website' && fromData[from[key]] !== '' && fromData[from[key]].substring(0, 4) !== 'www.' && ((fromData[from[key]].match(/\./g) || []).length <= 1)) {
          returnData[to[key]] = 'www.' + fromData[from[key]];
        }
      }
      return returnData;
    } else {
      /**REVERSE MODE DATA */
      from = serverKeys;
      to = stateKeys;
      /**assign data also */
      fromData = _.cloneDeep(serverData);
      toData = _.cloneDeep(stateData);
      for (let key in from) {
        if (fromData.hasOwnProperty(from[key]) && fromData[from[key]] !== null) {
          toData[to[key]] = fromData[from[key]];
          //check for website. from server to app  
          if (from[key] === 'websiteURL' && fromData[from[key]].substring(0, 4) === 'www.') {
            toData[to[key]] = fromData[from[key]].slice(4, fromData[from[key]].length);
          }
        }
      }
      /**check for team mebers also if reverse mode is false */
      if (!reverseMode && fromData.teamMembers !== null) {
        for (let i = 0; i < 4; i++) {
          if (!fromData.teamMembers[i]) {
          } else {
            /**data is present in server data */
            toData.teamMembers[i] = fromData.teamMembers[i];
          }
        }
      }
      return toData;
    }
  };

  touchPatcher = (dataValues, touchArray) => {
    for (let key in dataValues) {
      if (dataValues[key] !== '' && key !== 'teamMembers') {
        touchArray[key] = true;
      }
    }
    return touchArray;
  };

  setEmailToInvalid = () => {
    const newState = { ...this.state };
    newState.touched.emailAddress = true;
    newState.errors.emailAddress = [
      'Email already exists. Please change your email.',
    ];
    this.setState(newState, this.enableStepButton);
  };

  validateRegisterToken = () => {
    /**make loading true */
    this.startLoading();
    try {
      RegisterAgentValidate(this.state.registerToken)
        .then(data => {
          switch (data) {
            case true:
            case 'true':
              this.setState({
                isTokenExpired: false,
              });
              /**load register agent data */
              this.getRegisterAgentOnLoadData();
              /**get the designation data now */
              this.getAllDesignationsData();
              /**clean the form */
              break;
            case false:
            case 'false':
              this.setState({
                isTokenExpired: true,
              });
              break;
            default:
              this.setState({
                isTokenExpired: true,
              });
              break;
          }
          /**stop loading also */
          this.stopLoading();
        })
        .catch(err => {
          //showErrorNotification(this.props, "Unable to process request.");
          this.setState({
            isTokenExpired: true,
          });
          this.stopLoading();
        });
    } catch (err) {
      //showErrorNotification(this.props, "Unable to process request.");
      this.setState({
        isTokenExpired: true,
      });
      this.stopLoading();
    }
  };

  getRegisterAgentOnLoadData = () => {
    /**make loading true */
    this.startLoading();
    try {
      OnLoadRegisterAgentData(this.state.registerEmailAddress)
        .then(data => {
          if (Object.keys(data).length > 0) {
            /**get the value from data patcher */
            let xValue = this.dataPacther(false, this.state.values, data);
            this.setState(
              (prevState, props) => {
                return {
                  registerAgentData: data,
                  values: xValue,
                  touched: this.touchPatcher(xValue, prevState.touched),
                };
              },
              () => { }
            );
          } else {
            this.setState({
              registerAgentData: {},
            });
          }
          /**stop loading also */
          this.stopLoading();
        })
        .catch(err => {
          //showErrorNotification(this.props, "Unable to process request.");
          this.setState({
            registerAgentData: {},
          });
          this.stopLoading();
        });
    } catch (err) {
      //showErrorNotification(this.props, "Unable to process request.");
      this.setState({
        registerAgentData: {},
      });
      this.stopLoading();
    }
  };

  validateRegisterEmail = () => {
    /**first check for whether user entered valid email or not */
    //this.state.touched.emailAddress &&
    let haveErrors = validate(
      { emailemailAddress: this.state.values.emailAddress },
      { emailAddress: { email: true } }
    );
    if (haveErrors) {
      /* we have error in our email so return no need to call api */
      return;
    }
    /**make loading true */
    this.startLoading();
    try {
      RegisterAgentValidateEmail(
        this.state.values.emailAddress,
        this.state.registerAgentData.listgenUserID
      )
        .then(data => {
          switch (data) {
            case true:
            case 'true':
              /* email exists already */
              this.setEmailToInvalid();
              break;
            case false:
            case 'false':
              /* Do nothing */
              break;
            default:
              this.setEmailToInvalid();
              break;
          }
          /**stop loading also */
          this.stopLoading();
        })
        .catch(err => {
          //showErrorNotification(this.props, "Unable to process request.");
          this.setEmailToInvalid();
          this.stopLoading();
        });
    } catch (err) {
      //showErrorNotification(this.props, "Unable to process request.");
      this.setEmailToInvalid();
      this.stopLoading();
    }
  };

  makeDeisgnationDataSequence = designationDataArray => {
    /**first get the Realtor from list */
    let returnData = [];
    let xData = designationDataArray.find(
      item => item.listgenDesignationName === 'Realtor'
    );
    if (xData) {
      returnData.push(xData);
    }
    /**Now Broker Associate */
    xData = designationDataArray.find(
      item => item.listgenDesignationName === 'Broker Associate'
    );
    if (xData) {
      returnData.push(xData);
    }
    /**now short remaining in alphabetical order */
    designationDataArray.sort(function (a, b) {
      if (a.listgenDesignationName < b.listgenDesignationName) {
        return -1;
      }
      if (a.listgenDesignationName > b.listgenDesignationName) {
        return 1;
      }
      return 0;
    });
    /**now push to return data */
    designationDataArray.forEach(item => {
      if (
        item.listgenDesignationName !== 'Realtor' &&
        item.listgenDesignationName !== 'Broker Associate'
      ) {
        returnData.push(item);
      }
    });
    return returnData;
  };

  getAllDesignationsData = () => {
    /**make loading true */
    this.startLoading();
    try {
      GetAllDesignationsData()
        .then(data => {
          if (data.length > 0) {
            this.setState((prevState, props) => {
              return {
                designationData: this.makeDeisgnationDataSequence(data),
              };
            });
          } else {
            this.setState({
              designationData: [],
            });
          }
          /**stop loading also */
          this.stopLoading();
        })
        .catch(err => {
          //showErrorNotification(this.props, "Unable to process request.");
          this.setState({
            designationData: [],
          });
          this.stopLoading();
        });
    } catch (err) {
      //showErrorNotification(this.props, "Unable to process request.");
      this.setState({
        designationData: [],
      });
      this.stopLoading();
    }
  };

  validateAgentLicenseNumber = () => {
    const haveErrors =
      this.state.touched.agentLicenseNumber &&
      this.state.errors.agentLicenseNumber;
    /**check only if already don't have errors */
    if (!haveErrors) {
      if (
        this.state.registerAgentData.agentLicenseNumber !== null &&
        this.state.values.agentLicenseNumber !==
        this.state.registerAgentData.agentLicenseNumber
      ) {
        const newState = { ...this.state };
        newState.errors.agentLicenseNumber = [
          'Agent license number not matching with our records.',
        ];
        this.setState(newState);
      }
    }
  };

  validateBrokerageLicenseNumber = () => {
    const haveErrors =
      this.state.touched.brokerLicenseNumber &&
      this.state.errors.brokerLicenseNumber;
    /**check only if already don't have errors */
    if (!haveErrors) {
      if (
        this.state.registerAgentData.brokerLicenseNumber !== null &&
        this.state.values.brokerLicenseNumber !==
        this.state.registerAgentData.brokerLicenseNumber
      ) {
        const newState = { ...this.state };
        newState.errors.brokerLicenseNumber = [
          'Brokerage license number not matching with our records.',
        ];
        this.setState(newState);
      }
    }
  };

  isAValidZipCode = (filedName, value, errors) => {
    const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
    if(!isValidZip){
      errors[filedName] = ["Zipcode is invalid"];
    }
    return errors
  }

  validateWebsite = (value, errors) => {
    errors = !errors ? {} : errors;
    let pattern = /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}\.[a-zA-Z0-9]([a-zA-Z]{1,6})$/;
    let pattern1 = /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}\.[a-zA-Z0-9][a-zA-Z0-9-]{0,61}\.[a-zA-Z0-9]([a-zA-Z]{1,6})$/;
    let pattern2 = /^((https?):\/\/)?([a-zA-Z0-9][a-zA-Z0-9-]{0,61}\.)+[a-zA-Z0-9]{2,61}(\/[^\s]*)?$/;
  
    // If empty, do not validate
    if (value === '' || !value) {
      delete errors['website'];
      return errors;
    }
  
    // We have a value
    if (value.substring(0, 4) === 'www.') {
      errors['website'] = ["www. is not allowed at the URL start"];
    } else if (!pattern.test(value) && !pattern1.test(value) && !pattern2.test(value)) {
      errors['website'] = ["Invalid website URL"];
    }
  
    return errors;
  }; 

  copyValuesFromMailingToBilling = checkedValue => {
    const newState = { ...this.state };
    if (checkedValue) {
      newState.values.billingFirstName = newState.values.firstName;
      newState.values.billingLastName = newState.values.lastName;
      newState.values.billingAddressLine1 = newState.values.mailingAddressLine1;
      newState.values.billingAddressLine2 = newState.values.mailingAddressLine2;
      newState.values.billingCity = newState.values.city;
      newState.values.billingState = newState.values.state.toUpperCase();
      newState.values.billingZipcode = newState.values.zipcode;
      /**set touched to true also */
      newState.touched.billingFirstName = true;
      newState.touched.billingLastName = true;
      newState.touched.billingAddressLine1 = true;
      newState.touched.billingAddressLine2 = true;
      newState.touched.billingCity = true;
      newState.touched.billingState = true;
      newState.touched.billingZipcode = true;
      /**set mailing address also */
      newState.values.billingAddresSameAsMailingAddress = true;
      newState.touched.billingAddresSameAsMailingAddress = true;
    } else {
      newState.values.billingAddresSameAsMailingAddress = false;
      newState.touched.billingAddresSameAsMailingAddress = true;
    }
    let xScope = this;
    this.setState(newState, this.validateForm);
  };

  truncateTeamMember = data => {
    let newData = [];
    for (let row of data.teamMembers) {
      if (
        row.firstName !== '' ||
        row.lastName !== '' ||
        row.email != '' ||
        row.phone !== '' ||
        row.agentLicenseNumber !== '' ||
        row.mailingAddress1 !== '' ||
        row.mailingAddress2 !== '' ||
        row.city !== '' ||
        row.state !== '' ||
        row.zipcode !== ''
      ) {
        newData.push(row);
      }
    }
    data.teamMembers = newData;
    return data;
  };

  patchTeamMemberFirstRow = () => {
    const { values } = this.state;
    return {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.mobilePhone,
      email: values.emailAddress,
      agentLicenseNumber: values.agentLicenseNumber,
      mailingAddress1: values.mailingAddressLine1,
      mailingAddress2: values.mailingAddressLine2,
      city: values.city,
      state: values.state,
      zipcode: values.zipcode,
      agentSignature: values.agentSignature,
      agentDesignation: values.agentDesignation,
    };
  };

  // updateMailProBlockUnblockAsync = () => {
  //   try {
  //     UpdateMailproAfterRegistrations(this.state.registerAgentData.listgenUserID, this.state.registerAgentData.countyID);
  //   } catch (errTemp) {
  //     console.info('update mailpro api failed')
  //   }
  // }

  completeAgentRegistration = () => {
    let data = this.dataPacther(true, this.state.values, this.state.values);
    /**truncate team members first */
    data = this.truncateTeamMember(data);
    /**handle some custom methods */
    data['listgenUserPassword'] = this.state.values.password;
    data = _.omit(data, [
      'password',
      'confirmPassword',
      'cardNumber',
      'expiryMonth',
      'expiryYear',
      'cvc',
    ]);
    data.listgenUserID = this.state.registerAgentData.listgenUserID;
    data.ownedMLSID = this.state.registerAgentData.ownedMLSID;
    data.agentRegisterToken = this.state.registerToken;
    /**check for team members */
    if (!data.isMemberOfTeam) {
      /**assign the data null values if no team members is present */
      data.teamName = '';
      data.teamMembers = [];
    } else {
      /**patch first row from original data */
      data.teamMembers[0] = this.patchTeamMemberFirstRow();
    }
    /**check for billing address */
    if (this.state.values.billingAddresSameAsMailingAddress) {
      data.billingFirstName = this.state.values.firstName;
      data.billingLastName = this.state.values.lastName;
      data.billingAddressLine1 = this.state.values.mailingAddressLine1;
      data.billingAddressLine2 = this.state.values.mailingAddressLine2;
      data.billingCity = this.state.values.city;
      data.billingState = this.state.values.state;
      data.billingZipCode = this.state.values.zipcode;
    }
    this.startLoading();
    /**get history */
    const { history } = this.props;
    //data = {};
    // console.log('registration data logged');
    // console.log(data);
    // return;
    try {
      RegisterAgentOnSubmitAPI(data)
        .then(data => {
          switch (data) {
            case true:
            case 'true':
              //call the no waiting register API
              //this.updateMailProBlockUnblockAsync();
              /* email exists already */
              this.setState({
                registrationCompleted: true,
              });
              /**Reset all the storage */
              ResetAllLocalStorages();
              showSuccessNotification(
                this.props,
                'You have registered successfully.'
              );
              /**set timeout */
              setTimeout(() => {
                history.push('/logout?mode=4');
              }, 10000);
              break;
            case false:
            case 'false':
              showErrorNotification(
                this.props,
                'Unable to register you. Please try again.'
              );
              break;
            default:
              showErrorNotification(this.props, 'Something went wrong.');
              break;
          }
          /**stop loading also */
          this.stopLoading();
        })
        .catch(err => {
          showErrorNotification(this.props, 'Unable to process request as api failed.');
          this.stopLoading();
        });
    } catch (err) {
      showErrorNotification(this.props, 'Unable to process request due to invalid data.');
      this.stopLoading();
    }
  };

  addAgentCCData = () => {
    const { values } = this.state;
    this.startLoading();
    try {
      AddAgentCCInfo(
        this.state.registerAgentData.listgenUserID,
        values.emailAddress,
        values.cardNumber,
        values.expiryMonth,
        values.expiryYear,
        values.cvc,
        values.nameOnCard
      )
        .then(data => {
          //stop loading first
          this.stopLoading();
          switch (data) {
            case true:
            case 'true':
              /**call the complete registration api */
              this.completeAgentRegistration();
              break;
            case false:
            case 'false':
              showErrorNotification(
                this.props,
                'Unable to validate credit card. Please try again.'
              );
              break;
            default:
              showErrorNotification(
                this.props,
                'Something went wrong while validating credit card.'
              );
              break;
          }
        })
        .catch(err => {
          showErrorNotification(this.props, 'Unable to process credit card request.');
          this.stopLoading();
        });
    } catch (err) {
      showErrorNotification(this.props, 'Unable to process request.');
      this.stopLoading();
    }
  };

  handleNext = () => {
    /**check for last step */
    const steps = getSteps();
    if (this.state.activeStep !== steps.length - 1) {
      this.setState(state => ({
        activeStep: state.activeStep + 1,
      }), () => {
        window.scrollTo(0, 0);
      });
      /**Apply terms check */
      // const isTermsAccepted = this.state.acceptedTermsAndConditions;
      // if (isTermsAccepted && this.state.activeStep === 3) {
      //   /**send to 5 no step directly */
      //   this.setState(state => ({
      //     activeStep: 5,
      //   }));
      // } else {
      //   /**run other case normally */
      //   this.setState(state => ({
      //     activeStep: state.activeStep + 1,
      //   }));
      // }
    } else {
      /**First submit the card registration */
      this.addAgentCCData();
    }
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }), () => {
      window.scrollTo(0, 0);
    });
    /**Apply terms check */
    // const isTermsAccepted = this.state.acceptedTermsAndConditions;
    // if (isTermsAccepted && this.state.activeStep === 5) {
    //   this.setState(state => ({
    //     activeStep: 3,
    //   }));
    // } else {
    //   this.setState(state => ({
    //     activeStep: state.activeStep - 1,
    //   }));
    // }
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <Step1Form
            values={this.state.values}
            errors={this.state.errors}
            touched={this.state.touched}
            isValid={this.state.isValid}
            isLoading={this.state.isLoading}
            handleFieldChange={this.handleFieldChange}
            validateForm={this.validateForm}
            enableStepButton={this.enableStepButton}
            validateRegisterEmail={this.validateRegisterEmail}
            validateAgentLicenseNumber={this.validateAgentLicenseNumber}
            validateBrokerageLicenseNumber={this.validateBrokerageLicenseNumber}
            haveErrors={this.haveErrors}
            designationData={this.state.designationData}
          />
        );
      case 1:
        return (
          <Step2Form
            values={this.state.values}
            errors={this.state.errors}
            touched={this.state.touched}
            isValid={this.state.isValid}
            isLoading={this.state.isLoading}
            handleFieldChange={this.handleFieldChange}
            validateForm={this.validateForm}
            enableStepButton={this.enableStepButton}
            haveErrors={this.haveErrors}
          />
        );
      case 2:
        return (
          <Step3Form
            values={this.state.values}
            errors={this.state.errors}
            touched={this.state.touched}
            isValid={this.state.isValid}
            isLoading={this.state.isLoading}
            handleFieldChange={this.handleFieldChange}
            validateForm={this.validateForm}
            enableStepButton={this.enableStepButton}
            validateFile={this.validateFile}
            haveErrors={this.haveErrors}
            listgenUserID={this.state.registerAgentData.listgenUserID}
          />
        );
      case 3:
        return (
          <TeamDetailsForm
            values={this.state.values}
            errors={this.state.errors}
            touched={this.state.touched}
            isValid={this.state.isValid}
            isLoading={this.state.isLoading}
            handleFieldChange={this.handleFieldChange}
            handleTeamFieldChange={this.handleTeamFieldChange}
            validateForm={this.validateForm}
            enableStepButton={this.enableStepButton}
            notPartOfATeam={this.notPartOfATeam}
            isPartOfATeam={this.isPartOfATeam}
            numOfTeamMembers={this.state.numOfTeamMembers}
            addTeamMember={this.addTeamMember}
            addTeamMemberError={this.state.addTeamMemberError}
            deleteTeamMember={this.deleteTeamMember}
            listgenUserID={this.state.registerAgentData.listgenUserID}
            setTeamMemberTouched={this.state.setTeamMemberTouched}
            designationData={this.state.designationData}
          />
        );
      case 4:
        return (
          <Step4Form
            values={this.state.values}
            localValues={this.state.values}
            errors={this.state.errors}
            touched={this.state.touched}
            isValid={this.state.isValid}
            isLoading={this.state.isLoading}
            handleFieldChange={this.handleFieldChange}
            validateForm={this.validateForm}
            enableStepButton={this.enableStepButton}
            acceptTermsAndConditions={this.acceptTermsAndConditions}
            mlsAreaIds={this.state.values.ownedMLSID}
          />
        );
      case 5:
        return (
          <Step5Form
            values={this.state.values}
            errors={this.state.errors}
            touched={this.state.touched}
            isValid={this.state.isValid}
            isLoading={this.state.isLoading}
            handleFieldChange={this.handleFieldChange}
            validateForm={this.validateForm}
            enableStepButton={this.enableStepButton}
            copyValuesFromMailingToBilling={this.copyValuesFromMailingToBilling}
            registerToken={this.state.registerToken}
            email={this.state.values.emailAddress}
            completeAgentRegistration={this.completeAgentRegistration}
          />
        );
      default:
        return 'Unknown stepIndex';
    }
  };

  handleFieldChange = (field, value, event) => {
    const newState = { ...this.state };
    newState.submitError = null;
    newState.touched[field] = true;
    {
      /* special case for isMemberOfTeam, replacing checkbox event with boolean button */
    }
    /**apply login according to field types */
    if (field === 'isMemberOfTeam') {
      newState.values[field] = value;
    } else if (field === 'mobilePhone' || field === 'officePhone') {
      newState.values[field] = event.target.value.replace(/\D/g, '');
    } else if(field === 'zipcode' || field === 'officeZipcode' || field === 'billingZipcode') {
      newState.values[field] = event.target.value.replace(/[^0-9-]/g, "");
    } else if (
      field === 'agentPhoto' ||
      field === 'agentLogo' ||
      field === 'agentLogo2' ||
      field === 'agentSignature'
    ) {
      newState.values[field] = value;
    } else if (
      field === 'nameOnCard' ||
      field === 'cardNumber' ||
      field === 'expiryMonth' ||
      field === 'expiryYear' ||
      field === 'cvc'
    ) {
      newState.values[field] = value;
    } else {
      newState.values[field] =
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value;
    }
    this.setState(newState, this.validateForm);
  };

  handleTeamFieldChange = (field, value, keyID) => {
    const newState = { ...this.state };
    newState.submitError = null;
    newState.touched['teamMembers'][keyID][field] = true;
    // keyID !== 0 && value === '' ? false : true;
    if (field === 'phone') {
      newState.values['teamMembers'][keyID][field] = value.replace(/\D/g, '');
    } else if(field === 'zipcode'){
      newState.values['teamMembers'][keyID][field] = value.replace(/[^0-9-]/g, "");
    } else {
      newState.values['teamMembers'][keyID][field] = value;
    }
    /**set team member touched */
    this.setTeamMemberTouched();
    this.setState(newState, this.setTeamMemberTouched);
  };

  analyzeTeamMemberDetails = errors => {
    let noOfTeamMembers = this.state.numOfTeamMembers.length;
    if (noOfTeamMembers === 1) {
      return null;
    } else if (Array.isArray(errors) && errors.length !== noOfTeamMembers) {
      /**return only equal to no of team member errors */
      /**exclude no 1 array because its readonly and alreadt validated */
      let xData = [undefined];
      let allUndefined = true;
      for (let i = 1; i < noOfTeamMembers; i++) {
        xData.push(errors[i]);
        if (errors[i]) {
          allUndefined = false;
        }
      }
      return allUndefined ? undefined : xData;
    }
  };


  isAValidTeamDetailsZipCode = (filedName, value, errors) => {
    const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
    if(!isValidZip){
      errors[filedName] = ["Zipcode is invalid"];
      return errors
    }
    return undefined
  }

  customTeamMemberValidator = _.debounce(() => {
    const { values } = this.state;

    const newState = { ...this.state };
    let errors = [];
    for (let key in values.teamMembers) {
      errors[key] = validate(values.teamMembers[key], TeamMemberSchema);
      errors[key] = this.isAValidTeamDetailsZipCode("zipcode", values.teamMembers[key].zipcode, [])
    }

    errors = this.analyzeTeamMemberDetails(errors);
    newState.errors.teamMembers = errors || [];
    newState.isTeamMemberValid = !this.state.isTeamMemberTouched
      ? false
      : errors
        ? false
        : true;
    this.setState(newState);
    /**now check for enable step button */
    this.enableStepButton();
  }, 300);

  setTeamMemberTouched = () => {
    /**once a agent click on the field. no need to track values */
    this.setState(
      {
        isTeamMemberTouched: true,
      },
      this.customTeamMemberValidator
    );
  };

  validateForm = _.debounce(() => {
    const { values } = this.state;

    const newState = { ...this.state };
    let errors = validate(values, MainSchema);

    errors = this.validateWebsite(values.website, errors);
    errors = this.isAValidZipCode('zipcode', values.zipcode, errors);
    errors = this.isAValidZipCode('officeZipcode', values.officeZipcode, errors);
    errors = this.isAValidZipCode('billingZipcode', values.billingZipcode, errors);

    newState.errors = errors || {};
    newState.isValid = (errors && Object.keys(errors).length > 0) ? false : true;

    // newState.errors = errors || {};
    // newState.isValid = errors ? false : true;

    this.setState(newState);
    /**now check for enable step button */
    this.enableStepButton();
  }, 300);

  enableStepButton = () => {
    const { activeStep } = this.state;
    const newState = { ...this.state };
    const stepKeys = newState.stepValidationKeys[activeStep];
    let disableButton = false;
    /**apply special check for team members */
    if (activeStep === 3) {
      disableButton = !newState.isTeamMemberValid;
      /**now check for team member field */
      for (let key of stepKeys) {
        if (!newState.touched[key] || newState.errors[key]) {
          disableButton = true;
        }
      }
    } else {
      for (let key of stepKeys) {
        if (!newState.touched[key] || newState.errors[key]) {
          disableButton = true;
        }
      }
    }
    newState.stepButtonDisable[activeStep] = disableButton;
    this.setState(newState);
  };

  acceptTermsAndConditions = () => {
    this.setState({
      acceptedTermsAndConditions: true,
      activeStep: 5,
    });
  };

  notPartOfATeam = () => {
    const newState = { ...this.state };
    newState.values.isMemberOfTeam = false;
    newState.activeStep = 4;
    this.setState(newState);
    // this.setState({
    //   activeStep: 4,
    //   isMemberOfTeam: false,
    // });
  };

  isPartOfATeam = () => {
    const { values } = this.state;
    const firstTeamMember = {
      firstName: values.firstName,
      lastName: values.lastName,
      agentLicenseNumber: values.agentLicenseNumber,
      mailingAddress1: values.mailingAddressLine1,
      mailingAddress2: values.mailingAddressLine2,
      email: values.emailAddress,
      phone: values.mobilePhone,
      city: values.city,
      state: values.state.toUpperCase(),
      zipcode: values.zipcode,
      agentDesignation: values.agentDesignation,
      agentSignature: values.agentSignature,
    };
    let copyTeams = this.state.values.teamMembers.slice();
    copyTeams[0] = firstTeamMember;

    this.setState({
      values: {
        ...this.state.values,
        teamMembers: copyTeams,
      },
    });
  };

  addTeamMember = () => {
    const { numOfTeamMembers, values } = this.state;
    let lastMemberCount = numOfTeamMembers[numOfTeamMembers.length - 1];
    let nextTeamMember = lastMemberCount + 1;

    if (numOfTeamMembers.length < 4) {
      this.setState(
        {
          numOfTeamMembers: [...numOfTeamMembers, nextTeamMember],
          addTeamMemberError: false,
        },
        this.customTeamMemberValidator
      );
    } else {
      this.setState(
        {
          addTeamMemberError: true,
        },
        this.customTeamMemberValidator
      );
    }
  };

  deleteTeamMember = memberNum => {
    const { teamMembers } = this.state.values;
    const { numOfTeamMembers } = this.state;

    const newMemberForm = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      agentLicenseNumber: '',
      mailingAddress1: '',
      mailingAddress2: '',
      city: '',
      state: '',
      zipcode: '',
    };

    let filteredTeam = [
      ...teamMembers.slice(0, memberNum),
      ...teamMembers.slice(memberNum + 1),
      newMemberForm,
    ];
    this.setState(
      prevState => ({
        values: {
          ...prevState.values,
          teamMembers: filteredTeam,
        },
        numOfTeamMembers: numOfTeamMembers.slice(0, -1),
        addTeamMemberError: false,
      }),
      this.customTeamMemberValidator
    );
  };

  render() {
    const steps = getSteps();
    const {
      activeStep,
      isLoading,
      isTokenExpired,
      registrationCompleted,
      clockTimer,
    } = this.state;


    const { classes, className } = this.props;
    const rootClassName = classNames(className);
    return (
      <WebsiteLayout
        hideFooter={this.state.registrationCompleted}
        hideTopbar={this.state.registrationCompleted}
      >
        {/* Token level validation begin */}

        <Fragment>
          <div className={classes.root}>
            {registrationCompleted ? (
              <div className={classes.successGrid}>
                <Grid class={classes.grid} container>
                  <Grid lg={3} md={3} sm={false} xs={false} />
                  <Grid lg={6} md={6} sm={12} xs={12}>
                    <div className={classes.listgenLogoHolder}>
                      <div className={classes.listgenLogoWrapper}>
                        <img
                          src={this.listgenLogo}
                          className={classes.listgenLogoTag}
                        />
                        <img
                          src={this.listgenAltLogo}
                          className={classes.listgenAltTag}
                        />
                      </div>
                    </div>
                    <Portlet>
                      <PortletHeader>
                        <Typography
                          variant="h4"
                          className={classNames(
                            classes.fieldSuccess,
                            classes.marginBottomSuccessHeader
                          )}
                        >
                          CONGRATULATIONS!
                        </Typography>
                      </PortletHeader>
                      <PortletContent>
                        <Typography
                          variant="subtitle1"
                          className={classes.fieldSuccessBody}
                        >
                          You have successfully registered as an approved user
                          of ListGen's powerful listing generating system.
                          <br />
                          <br />
                          Prior to registering you were asked to review our
                          AGREEMENT TERMS. Just to make really sure you're on
                          board with how we manage our business and how we work
                          hard to help you manage yours, please click the link
                          below to review them again.
                          <br />
                          <br />
                          It'll only take a few minutes...and we're here if you
                          have any questions about how this all works.
                          <br />
                          Welcome!!
                        </Typography>
                        <br />
                        <Typography variant="subtitle2" align="center">
                          You have to login again to continue.
                        </Typography>
                      </PortletContent>
                      <PortletFooter className={classes.portletFooter}>
                        <div className={classes.successGridActions}>
                          {/* <Link to="/legal">
                            <Button variant="outlined" color="primary">
                              Review ListGen's Terms & Policies
                            </Button>
                          </Link> */}
                          <Link to="/logout?mode=4">
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.marginLeftSuccessGridButton}
                            >
                              Got it! Let's go!
                            </Button>
                          </Link>
                        </div>
                      </PortletFooter>
                    </Portlet>
                  </Grid>
                  <Grid lg={3} md={3} sm={false} xs={false} />
                </Grid>
              </div>
            ) : (
              <Grid container className={classes.grid} spacing={0}>
                <Grid item lg={1} />
                <Grid item lg={10}>
                  <Portlet className={rootClassName}>
                    <PortletHeader>
                      <PortletLabel subtitle="" title="Agent Registration" />
                    </PortletHeader>
                    {!isTokenExpired ? (
                      <div>
                        <PortletContent>
                          <Grid container className={classes.grid} spacing={0}>
                            <Grid item lg={12}>
                              <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map(label => (
                                  <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                  </Step>
                                ))}
                              </Stepper>
                              <Divider />
                              <div className={classes.stepperBody}>
                                <form autoComplete="off" noValidate>
                                  {this.getStepContent(activeStep)}
                                </form>
                              </div>
                            </Grid>
                          </Grid>
                        </PortletContent>
                        <PortletFooter className={classes.portletFooter}>
                          <div>
                            {activeStep === steps.length ? (
                              <div>
                                <Typography className={classes.instructions}>
                                  All steps completed
                                </Typography>
                                <Button onClick={this.handleReset}>
                                  Reset
                                </Button>
                              </div>
                            ) : (
                              <div>
                                <div className={classes.footerButtons}>
                                  <div
                                    className={classes.footerButtonsLeftRight}
                                  >
                                    {activeStep !== 0 ? (
                                      <Button
                                        onClick={this.handleBack}
                                        className={classes.backButton}
                                      >
                                        Back
                                      </Button>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                  <div className={classes.footerButtonsCenter}>
                                    {isLoading ? (
                                      <CircularProgress
                                        className={classes.progress}
                                      />
                                    ) : (
                                      activeStep !== 4 && (
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={this.handleNext}
                                          disabled={
                                            //false &&
                                            this.state.stepButtonDisable[
                                            activeStep
                                            ]
                                          }
                                        >
                                          {activeStep === steps.length - 1
                                            ? 'Agree & Submit Registration'
                                            : activeStep === 4
                                              ? ''
                                              : 'Next'}
                                        </Button>
                                      )
                                    )}
                                  </div>
                                  <div
                                    className={classes.footerButtonsLeftRight}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </PortletFooter>
                      </div>
                    ) : (
                      <div className={classes.invalidRequest}>
                        <Grid className={classes.grid}>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              variant="subtitle2"
                              className={classes.fieldError}
                            >
                              Sorry, Invalid request.
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </Portlet>
                </Grid>
                <Grid item lg={1} />
              </Grid>
            )}
          </div>
        </Fragment>
        {/* Token level validation end */}
      </WebsiteLayout>
    );
  }
}

RegisterAgent.propTypes = {
  classes: PropTypes.object,
};

export default compose(
  withSnackbar,
  withStyles(styles)
)(RegisterAgent);
