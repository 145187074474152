import React, { Component } from "react";
import CoverPage from "views/Pma/PmaEditor/FirstPageComponents/CoverPage";

class TableQuadrantsFirstRight extends Component {
  render() {
    const {
      agentData,
      coverPageDetails,
      currentArea,
      hoData,
      version,
      printVersion,
      quarterlyAreaTable,
      quadrantModalOpen,
    } = this.props;
    return (
      <div className="table-quadrants-first-right">
        <CoverPage
          marketHighLightsData={quarterlyAreaTable.marketHighLightsData}
          agentData={agentData}
          coverPageDetails={coverPageDetails}
          currentArea={currentArea}
          hoData={hoData}
          photoNum="1"
          currentSoldListings={quarterlyAreaTable.currentSoldListings}
          quarterlyAreaTable={quarterlyAreaTable}
          // printDate="December 3"
          printVersion={printVersion}
          version={version}
          quadrantModalOpen={quadrantModalOpen}
        />
      </div>
    );
  }
}

export default TableQuadrantsFirstRight;
