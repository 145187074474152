import React, { Component } from "react";
import YETwoFirstPage from "./YETwoFirstPage";
import YETwoSecondPage from "./YETwoSecondPage";
import PropTypes from "prop-types";

class YETwo extends Component {
  componentDidMount() {
    if (this.props.printVersion) {
      require("./ye2print.scss");
      // require("./ye2-alt-print.scss");
    } else {
      require("./ye2styles.scss");
      require("./ye2-alt-editor.scss");
    }
  }
  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,
      currentArea,
      mlsAreas,
      coverPageDetails,
      aprilData,
      flipBook,
      demoAccountHOName,
      demoAccountCoverPhoto,
      demoAccountAreaName,
      demoAccountCity,
      quarterlyAreaTable,
      testimonials,
      typeOfVersion,
      handlePropertyHighlightDialog,
      propertyPhotoDetailsDTO,
      pmaQuarterlyCombinedCityDTO,
      quadrantDetails,
      oldPhotoDetails,
      photoTableData,
      quarterlyCityTable,
      isDemo,
      MQCityDetails,
      bubbleBoxes,
      missionStatement,
    } = this.props;
    let useQuadrantContainer = false;
    {
      /*let errorsList = [];

    if (
      missionStatement &&
      missionStatement.missionStatementContent &&
      missionStatement.missionStatementContent.includes("XX")
    ) {
      errorsList.push(`MISSION STATEMENT ERROR: Using default with no years
        indicated - XX`);
    }
*/
    }
    return (
      <div>
        <div
          className={printVersion ? "ye2-print-page" : "ye2-pma-editor-page"}
        >
          {/*{printVersion && errorsList && errorsList.length >= 1 && (
            <div className="error-sticky-note-container">
              <div className="error-sticky-note-inner-container">
                <ul className="error-sticky-list">
                  {errorsList.map((error) => (
                    <li className="error-sticky-note-line">{error}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}*/}

          <YETwoFirstPage
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version={version}
            coverPageDetails={coverPageDetails}
            aprilData={aprilData}
            demoAccountHOName={demoAccountHOName}
            demoAccountCoverPhoto={demoAccountCoverPhoto}
            demoAccountAreaName={demoAccountAreaName}
            testimonials={testimonials}
            typeOfVersion={typeOfVersion}
            handlePropertyHighlightDialog={handlePropertyHighlightDialog}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            quadrantDetails={quadrantDetails}
            quarterlyAreaTable={quarterlyAreaTable}
            isDemo={isDemo}
            bubbleBoxes={bubbleBoxes}
            quadrantDetails={quadrantDetails}
          />
          <YETwoSecondPage
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version={version}
            aprilData={aprilData}
            demoAccountCity={demoAccountCity}
            demoAccountHOName={demoAccountHOName}
            quarterlyAreaTable={quarterlyAreaTable}
            quarterlyCityTable={quarterlyCityTable}
            pmaQuarterlyCombinedCityDTO={pmaQuarterlyCombinedCityDTO}
            oldPhotoDetails={oldPhotoDetails}
            photoTableData={photoTableData}
            isDemo={isDemo}
            MQCityDetails={MQCityDetails}
            missionStatement={missionStatement}
          />
        </div>
      </div>
    );
  }
}

export default YETwo;
