import React, { Component } from "react";

import FourthPageComponents from "views/Pma/PmaEditor/FourthPageComponents";
import { propertyUtil } from "views/Pma/PropertyUtil";

class YETwoFirstLeft extends Component {
  render() {
    const {
      printVersion,
      version,
      agentData,
      hoData,
      currentArea,
      mlsAreas,

      coverPageDetails,

      testimonials,
      handlePropertyHighlightDialog,
      propertyPhotoDetailsDTO,
      quarterlyAreaTable,
      bubbleBoxes,
      quadrantDetails,
      quadrantModalOpen,
    } = this.props;

    const { mlsAreaID } = currentArea;
    // const { pmaQuarterlyAreaDTO } = aprilData;
    const { listgenUserID } = agentData;
    // let isAnnetteVait = listgenUserID === "x101001";
    // let isJohnsons = listgenUserID === "x100988";
    // let isKateryna = listgenUserID === "x102286" || listgenUserID === "x105047";
    let useBackpageBoxes = false;

    let propertyHighlightsTitle;
    let propertyHighlightsSubtitle;
    let homesSoldPreviousYear;
    let homesSoldCurrentYear;
    let totalSalePricePreviousYear;
    let totalSalePriceCurrentYear;
    let photoDetails;
    let propertyHighlightsFooterText1;
    let propertyHighlightsFooterText2;

    const { isMemberOfTeam } = agentData;
    const { roundSalePrice } = propertyUtil;
    let selected;
    let sortSelected;
    let isDemo;

    if (
      propertyPhotoDetailsDTO &&
      propertyPhotoDetailsDTO.photoDetails &&
      propertyPhotoDetailsDTO.photoDetails.length >= 1
    ) {
      propertyHighlightsTitle = propertyPhotoDetailsDTO.propertyHighlightsTitle;
      propertyHighlightsSubtitle =
        propertyPhotoDetailsDTO.propertyHighlightsSubtitle;
      propertyHighlightsFooterText1 =
        propertyPhotoDetailsDTO.propertyHighlightsFooterText1;
      propertyHighlightsFooterText2 =
        propertyPhotoDetailsDTO.propertyHighlightsFooterText2;

      homesSoldPreviousYear = propertyPhotoDetailsDTO.homesSoldPreviousYear;
      homesSoldCurrentYear = propertyPhotoDetailsDTO.homesSoldCurrentYear;
      totalSalePricePreviousYear =
        propertyPhotoDetailsDTO.totalSalePricePreviousYear;
      totalSalePriceCurrentYear =
        propertyPhotoDetailsDTO.totalSalePriceCurrentYear;
      photoDetails = propertyPhotoDetailsDTO.photoDetails;

      selected = photoDetails.filter((prop) => prop.isSystemSelected === true);
      //selected = photoDetails.filter((prop) => prop.displayOrder > 0);

      if (selected.length === 0) {
        selected = photoDetails.slice(0, 5);
      }
      sortSelected = selected.sort((a, b) => a.displayOrder - b.displayOrder);
    } else {
      photoDetails = [{ displayOrder: "1" }];
    }

    let propSoldTotal = +totalSalePriceCurrentYear;
    // let constructedTitle = `A sampling of some of the beautiful homes ${
    //   isMemberOfTeam ? "We" : "I"
    // } represented for both Sellers and Buyers`;
    //
    // // `${
    // //   isMemberOfTeam ? "We" : "I"} `
    // // } listed and sold ${+homesSoldCurrentYear} ${
    // //   +homesSoldCurrentYear === 1 ? "home" : "homes"
    // // }, totaling ${roundSalePrice(propSoldTotal, false, "writtenOut")} in 2021`;
    // let constructedSubtitle =
    //   "A Record Year of High Home Prices, Fast Transactions, and Extraordinary Buyer Demand";

    let hasTitle =
      propertyHighlightsTitle && propertyHighlightsTitle.length > 1
        ? true
        : false;
    let hasSubtitle =
      propertyHighlightsSubtitle && propertyHighlightsSubtitle.length > 1
        ? true
        : false;
    let hasFooterText1 =
      propertyHighlightsFooterText1 && propertyHighlightsFooterText1.length > 1
        ? true
        : false;
    let hasFooterText2 =
      propertyHighlightsFooterText2 && propertyHighlightsFooterText2.length > 1
        ? true
        : false;

    if (listgenUserID === "105") {
      //sortSelected = quarterlyAreaTable.currentSoldListings.slice(0, 6);
      isDemo = true;
      hasTitle = true;

      propertyHighlightsTitle = "The magic of new beginnings";
      hasSubtitle = true;
      propertyHighlightsSubtitle =
        "A sampling of the beautiful homes I represented";
      hasFooterText1 = true;
      propertyHighlightsFooterText1 =
        "Contact me today for complimentary home valuation.";
      hasFooterText2 = true;
      propertyHighlightsFooterText2 = "415-256-7890";
    }
    let useQuadrants = false;

    if (
      !propertyPhotoDetailsDTO ||
      !photoDetails ||
      !sortSelected ||
      listgenUserID === "104597" ||
      listgenUserID === "106591"
      // listgenUserID === "102894" ||
      // listgenUserID === "102378" ||
      // listgenUserID === "106550" ||
      // mlsAreaID === "SMA-437" ||
      // listgenUserID === "105016" ||
      // listgenUserID === "104597" ||
      // listgenUserID === "105047"
    ) {
      useQuadrants = true;
    } else if (mlsAreaID === "x") {
      useBackpageBoxes = true;
    }
    useQuadrants = true;
    return (
      <FourthPageComponents
        currentArea={currentArea}
        printVersion={printVersion}
        agentData={agentData}
        isDemo={isDemo}
        hoData={hoData}
        version={version}
        coverPageDetails={coverPageDetails}
        quadrantModalOpen={quadrantModalOpen}
        mlsAreaID={mlsAreaID}
        currentSoldListings={sortSelected}
        handlePropertyHighlightDialog={handlePropertyHighlightDialog}
        quarterlyAreaTable={quarterlyAreaTable}
        title={hasTitle ? propertyHighlightsTitle : ""}
        subtitle={hasSubtitle ? propertyHighlightsSubtitle : ""}
        footerText1={hasFooterText1 ? propertyHighlightsFooterText1 : ""}
        footerText2={hasFooterText2 ? propertyHighlightsFooterText2 : ""}
        mlsAreaID={currentArea.mlsAreaID}
        quadrantDetails={quadrantDetails}
        testimonials={testimonials}
        hoData={hoData}
        bubbleBoxes={bubbleBoxes}
        sortSelected={sortSelected}
        hasTitle={hasTitle}
        propertyHighlightsTitle={propertyHighlightsTitle}
        hasSubtitle={hasSubtitle}
        propertyHighlightsSubtitle={propertyHighlightsSubtitle}
        hasFooterText1={hasFooterText1}
        propertyHighlightsFooterText1={propertyHighlightsFooterText1}
        hasFooterText2={hasFooterText2}
        propertyHighlightsFooterText2={propertyHighlightsFooterText2}
        useQuadrants={useQuadrants}
        useBackpageBoxes={useBackpageBoxes}
        propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
      />
    );
  }
}

export default YETwoFirstLeft;
