import React, { Component } from "react";
import QVFourPhotoSection from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPhotoSection";
import QVFourPresentedByFooter from "views/Pma/PmaEditor/ThirdPageComponents/QVFourPresentedByFooter";
import AgentLogoHeader from "views/Pma/PmaEditor/ThirdPageComponents/AgentLogoHeader";
import QVFourPS from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPS";
import QVFourPhotos from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage/QVFourPhotos";
import YETwoShortenedMarketTable from "views/Pma/PmaEditor/YearEnd2022/YETwo/YETwoSecondPage/YETwoShortenedMarketTable";
import MQThreeAvailableHomes from "views/Pma/PmaEditor/MidQuarter/MQThree/MQThreeSecondPage/MQThreeAvailableHomes";
import QRCode from "views/Pma/PmaEditor/ThirdPageComponents/QRCode";
import MissionStatementBlock from "views/Pma/PmaEditor/ThirdPageComponents/MissionStatementBlock";
import ThirdPageComponents from "views/Pma/PmaEditor/ThirdPageComponents";

class YETwoSecondRight extends Component {
  render() {
    const {
      currentArea,
      agentData,
      printVersion,
      version,
      hoData,
      mlsAreas,
      aprilData,
      demoAccountHOName,
      propertyPhotoDetailsDTO,
      oldPhotoDetails,
      photoTableData,
      quarterlyAreaTable,
      quarterlyCityTable,
      MQCityDetails,
      isDemo,
      missionStatement,
    } = this.props;
    const { listgenUserID } = agentData;
    const { mlsAreaID } = currentArea;
    const { currentSoldListings } = quarterlyAreaTable;

    return (
      <ThirdPageComponents
        printVersion={printVersion}
        missionStatement={missionStatement}
        agentData={agentData}
        currentArea={currentArea}
        hoData={hoData}
        mlsAreas={mlsAreas}
        version={version}
        mailingNum="qv4"
        demoAccountHOName={demoAccountHOName}
        isDemo={isDemo}
        smallerQR={true}
        quarterlyAreaTable={quarterlyAreaTable}
        currentSoldListings={currentSoldListings}
      />
    );
  }
}

export default YETwoSecondRight;
